import React, { useState, useEffect } from 'react';
import { Box } from "@material-ui/core";
import BannerDesign from "../AES_AIM/BannerDesign";
import AhsBanner from '../pictures/ahs-banner.png';
import AhsUnionVector from '../pictures/ahs-union.png';
import AhsDoctor1 from '../pictures/ahs-doctor-pic1.png';
import AhsDoctor2 from '../pictures/ahs-doctor-pic2.png';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AhsServices from './AhsServices';
import AhsSvcIcon1 from '../pictures/ahs-svc-icon1.png';
import AhsSvcIcon2 from '../pictures/ahs-svc-icon2.png';
import AhsSvcIcon3 from '../pictures/ahs-svc-icon3.png';
import AhsDonateDoctor from '../pictures/ahs-donate-doctor.png';
import AhsDonateIcon1 from '../pictures/ahs-donate-icon1.png';
import AhsDonateIcon2 from '../pictures/ahs-donate-icon2.png';
import AhsDonateIcon3 from '../pictures/ahs-donate-icon3.png';
import AhsWorksDoctor1 from '../pictures/ahs-works-doctor1.png';
import AhsWorksDoctor2 from '../pictures/ahs-works-doctor2.png';
import AhsWorksDoctor3 from '../pictures/ahs-works-doctor3.png';
import { useMediaQuery, useTheme } from '@material-ui/core';
import AhsWorks from './AhsWorks';
import AhsStories from './AhsStories';
import Metadata from '../Metadata';
import CustomButton from '../ReusableComponents/CustomButton';
import CustomTypography from '../CustomTypography';
import CustomCarousel from '../CustomCarasouel';
import heroVideo from '../pictures/hero_video.mp4';
import VideoPlay from '../VideoPlay';



const Ahs = () => {
    const autoPlayInterval = 5000;

    const theme = useTheme();
    //complete Ahs page

    const [currentSlide1, setCurrentSlide1] = useState(0);//stories
    const [currentSlideStories, setCurrentSlidestories] = useState(0);//stories

    const totalSlides = 4; // Total number of slides in the carousel
    const totalSlidesStories = 4;

    const handleNext = () => {
        setCurrentSlide1((prevSlide) => (prevSlide === totalSlides - 1 ? 0 : prevSlide + 1));
    };

    //for stories slider

    const handleNextStories = () => {
        setCurrentSlidestories((prevSlide) => (prevSlide === totalSlides - 1 ? 0 : prevSlide + 1));
    };

    const handlePrevStories = () => {
        setCurrentSlidestories((prevSlide) => (prevSlide === 0 ? totalSlides - 1 : prevSlide - 1));
    };

    const handleChangeSlideStories = (index) => {
        setCurrentSlidestories(index); // Update current slide index when a button is clicked
    };



    //custom carasouel 

    // Auto play functionality
    useEffect(() => {
        const timer = setInterval(() => {
            handleNext();
        }, autoPlayInterval);

        return () => clearInterval(timer); // Clean up the interval on component unmount
    }, [currentSlide1]);


    const slides = [
        <AhsServices icon={AhsSvcIcon1} head="General Clinic" facilityCard="yes"
            para="We offer comprehensive healthcare services for a variety of common ailments and medical concerns. Services include routine check-ups, diagnostic tests, minor procedures, vaccinations, and treatment for acute and chronic illnesses. We ensure primary care for the deserving and underprivileged patients who visit us."
        />,

        <AhsServices icon={AhsSvcIcon2} head="ECG Clinic" facilityCard="yes"
            para="We provide essential electrocardiogram services to monitor heart health. Our patients receive ECG tests to detect heart abnormalities, assess heart rhythm, and aid in diagnosing cardiovascular conditions, ensuring timely and appropriate treatment."
        />,
        <AhsServices icon={AhsSvcIcon3} head="Cornea Transplant Project" facilityCard="yes"
            para="We have signed an MoU with the College of Ophthalmology and Life Sciences, Mayo Hospital, which allows Akhuwat to donate corneas from the deceased to the said hospital. Consequently, the hospital will implant them for free on deserving patients."
        />,
        <AhsServices icon={AhsSvcIcon3} head="Cornea Transplant Project" facilityCard="yes"
            para="We have signed an MoU with the College of Ophthalmology and Life Sciences, Mayo Hospital, which allows Akhuwat to donate corneas from the deceased to the said hospital. Consequently, the hospital will implant them for free on deserving patients."
        />,
    ];



    const donateSlides = [
        <AhsServices icon={AhsDonateIcon1} head="We Help Deserving People"
            para="Many people are unable to access healthcare services, especially 
            patients suffering from diabetes and hepatitis. We help them by providing 
            efficient and affordable diagnosis, medication, and treatment."
            donateCard="yes"
        />,

        <AhsServices icon={AhsDonateIcon2} head="We Spread Awareness"
            para="From free medical camps and spreading awareness about diabetes, we make
         people aware regarding their personal health responsibilities. We also 
         provide Khawajasira Rehabilitation program to help them overcome their
          alienation in society and create dialogue with communities."
            donateCard="yes"
        />,
        <AhsServices icon={AhsDonateIcon3} head="Support During Disasters"
            para="We provide first aid and healthcare through camps during natural
                             disasters. During the Floods 2022, our teams went to different parts of
                              Pakistan and provided basic healthcare to the flood affectees. During the
                               COVID-19 pandemic, we provided medical advice online and conducted free 
                               tests for those who couldn’t afford them."
            donateCard="yes"
        />,


        <Box className='lg:h-[500px] h-auto  rounded-[30px] lg:py-10 lg:px-10 py-8 px-5 '
            style={{
                backgroundImage: ` radial-gradient(58.96% 94.34% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, 
                    rgba(0, 0, 0, 0) 70%),
                    linear-gradient(180deg, rgba(0, 0, 0, 0) 11%, rgba(0, 0, 0, 0.6) 100%),
                    url(${AhsDonateDoctor})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                boxShadow: `
                    0px 2px 6px 2px #00000026,
                    0px 1px 2px 0px #0000004D
                  `
            }}>

            <h1 className='font-manrope font-bold lg:text-[35px] text-[20px] lg:leading-[52.5px]
                    leading-[27.32px]
                    text-center text-white'>Help us in our Mission.</h1>

            <p className='pt-6 font-manrope font-normal lg:font-semibold text-center
                     lg:text-[24px] text-[16px] lg:leading-[32.78px]
                    leading-[21.86px] text-white'>
                Help those in need, We need your love and support to keep supporting those in need.</p>


            <Box className=" flex justify-center   items-center pt-6 ">
                <CustomButton btnText='Learn more' btnColor='white' btnBackground='AhsPrimary'
                    btnURL='/ahs' />
            </Box>
        </Box>



    ]




    return (
        <Box >
            <Metadata title="Health" />
            {/* Banner section */}

            <Box>

                <BannerDesign imageURL={AhsBanner} headPart1={'Akhuwat Health Services'} headPart2={'(AHS)'}
                    belowHead={'Akhuwat Health Services (AHS)'}
                    belowPara1="Akhuwat Health Services runs a health center in Township, Lahore since 2009 
                    and aims to provide effective and affordable healthcare services to financially vulnerable
                     people. AHS also offers subsidized medicines (e.g., diabetes injections and footwear),
                      lab tests, free examination, consultation, and nursing services. "

                    belowheadColor="text-AhsPrimary"
                    belowParaColor="text-customBlack"
                    AkspLeftUnion={AhsUnionVector}
                    borderColor="AhsPrimary"
                    donateBtn="yes"
                />

            </Box>

            {/* improve mental health section */}

            <Box className="lg:py-120px lg:px-120px md:py-60px dm:px-10 py-6 px-4 bg-DustyRose">
                <Box className='max-w-screen-2xl mx-auto'>  {/* max width applied */}

                    <CustomTypography
                        as='h3' variant='h3' color='text-AhsPrimary' weightFont='font-semibold'
                    >
                        [Akhuwat Health Services runs a health center in Township, Lahore since 2009 and
                        aims to provide effective and affordable healthcare services to financially
                        vulnerable people. AHS also offers subsidized medicines (e.g., diabetes injections
                        and footwear), lab tests, free examination, consultation, and nursing services.]
                    </CustomTypography>
                </Box>
            </Box>



            {/*  Video sectoin  */}

            <Box className='lg:h-[718px] md:h-[500px] h-[421px] '
                style={{
                    backgroundImage: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                    url(${AhsBanner})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}>
                <Box className='flex flex-col lg:justify-center md:justify-center justify-end h-full 
                lg:px-[128px] md:px-16 px-4 md:py-0 lg:py-0  lg:w-[900px] md:w-[500px] w-auto  py-6
               max-w-screen-2xl   '>

                    <p className='font-manrope text-white font-extrabold text-[14px] 
                    lg:leading-[14px] lg:text-left md:text-left text-center '>
                        Akhuwat health services</p>

                    <CustomTypography className='pt-2' as="h2" variant="h21" color="text-white " weightFont="font-bold"
                        alignment="lg:text-left md:text-left text-center"
                    >
                        Care that everyone deserves
                    </CustomTypography>

                    <Box className='pt-6 flex lg:justify-start md:justify-start justify-center'>
                        <CustomButton btnText='Watch video' btnURL='/video' btnColor='customBlack'
                            btnBackground='white' />
                    </Box>
                </Box>
            </Box>





            {/* Facilites & Services section */}
            <Box className='bg-MintCream'>

                <Box className=" lg:py-120px md:py-60px md:px-10 py-6 lg:px-120px px-4 ">

                </Box>

                {/* Carousel   max width applied*/}
                <Box className='max-w-screen-2xl mx-auto lg:pb-120px md:pb-10 pb-6 pl-0 pr-0' >

                    <CustomCarousel slides={slides} slideWidth={{
                        lg: 386,
                        md: 345,
                        sm: 310
                    }} gap={20}
                        ArrowPosition='top'
                        ArrowActiveColor='#329A51'
                        ArrowInActiveColor='white'
                        ArrowAligned='end'
                        sliderTitle="Facilities & Services"
                        SliderTitleColor='text-AhsPrimary'
                        showLineNav='yes'

                    />

                </Box>
            </Box>


            {/* Video section */}

            <Box className="lg:py-16 lg:px-16 md:py-10 md:px-10 py-8 px-4 bg-white" >
                <VideoPlay thumbnailPicture={AhsDoctor2} videoSrc={heroVideo} />

            </Box>


            {/* Why donate ? section */}

            <Box>

                <Box className='bg-white lg:py-120px lg:px-120px py-6 px-4 md:py-60px md:px-10'>
                    <Box className='max-w-screen-2xl mx-auto'>
                        <CustomTypography as="h2" variant="h21" color="text-AhsPrimary" weightFont="font-bold"
                            alignment='text-center'>
                            Why Donate to AHS?
                        </CustomTypography>

                        <Box className='lg:grid grid-cols-2 md:hidden hidden pt-[74px] gap-5'>

                            {donateSlides}

                        </Box>



                    </Box>


                </Box>

                {/* Carasoule for mobile */}

                <Box className='md:pb-60px pb-6 lg:hidden md:pt-[72px]'>

                    <CustomCarousel slides={donateSlides} slideWidth={{
                        lg: 386,
                        md: 341,
                        sm: 310
                    }} gap={20}



                    />

                </Box>

            </Box>


            {/* Work section */}

            <Box className='bg-customGreen lg:py-120px lg:px-120px py-6 px-4 md:py-60px md:px-10'>
                <Box className='max-w-screen-2xl mx-auto'>
                    <CustomTypography as="h2" variant="h21" color="text-white" weightFont="font-bold" alignment='text-center'>
                        Why It Works
                    </CustomTypography>

                    <Box className='lg:grid grid-cols-2 hidden  pt-120px  overflow-hidden'>
                        <Box className='bg-white p-60px rounded-l-[20px]'>


                            <CustomTypography
                                as='h3' variant='h3' color='text-customGreen' weightFont='font-medium'

                            >
                                Community Service
                            </CustomTypography>

                            <CustomTypography className="pt-10"
                                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'


                            >
                                Akhuwat Healthcare Services (AHS) doesn’t aim at profit but community service by
                                providing preventive, primary and curative health services that are accessible at affordable cost to underprivileged people.
                            </CustomTypography>

                            <CustomTypography className="pt-10"
                                as='p' variant='p2' color='text-topGreen' weightFont='font-normal'


                            >
                                We strive to improve the mental and social well-being of people along with
                                their mental health.
                            </CustomTypography>
                        </Box>
                        <Box className='rounded-r-[20px]' style={{
                            backgroundImage: `url(${AhsWorksDoctor1})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover'
                        }}>

                        </Box>

                    </Box>

                    <Box className='grid lg:grid-cols-2 grid-cols-1 pt-52px lg:gap-5 gap-[44px]'>

                        <Box className='lg:hidden'>
                            <AhsWorks gridImage={AhsWorksDoctor1} topHead="Community Service"
                                para="Akhuwat Healthcare Services (AHS) doesn’t aim at profit but community service by
                             providing preventive, primary and curative health services that are accessible 
                             at affordable cost to underprivileged people."
                                belowHead="We strive to improve the mental and social well-being of people along with 
                                their mental health."
                                paddingLength="40px" />
                        </Box>

                        <Box>
                            <AhsWorks gridImage={AhsWorksDoctor2} topHead="Poverty-alleviation"
                                para="Akhuwat is committed to poverty-alleviation. One important sector, where 
                         deserving and underprivileged people need help and support, is health. We practice
                          Mawakhat (solidarity) with people who can’t access healthcare services easily by
                           providing them a range of services and subsidized medicines."
                                paddingLength="40px" />
                        </Box>

                        <Box>
                            <AhsWorks gridImage={AhsWorksDoctor3} topHead="Awareness Campaigns"
                                para="For the rural areas of Punjab, Akhuwat organizes tele-medical facilities. We 
                         also educate people to maintain a healthy lifestyle which aims at promoting behaviors
                          that sustain well-being. We also spread awareness about sensitive illnesses, such as
                           breast cancer and hepatitis."
                                paddingLength="40px" />
                        </Box>


                    </Box>

                </Box>
            </Box>


            {/* Stories section */}

            <Box className="bg-white lg:py-140px py-60px lg:px-120px px-4 ">
                <Box className='max-w-screen-2xl mx-auto'>
                    <Box className='grid lg:grid-cols-2 grid-cols-1'>
                        <Box className='flex lg:justify-start justify-center items-center'>


                            <CustomTypography as="h2" variant="h2" color="text-customBlack" weightFont="font-semibold">
                                AHS Stories
                            </CustomTypography>
                        </Box>


                        <Box className='col-span-1 lg:flex hidden items-center justify-end '>
                            <Box
                                onClick={handlePrevStories}
                                className={`cursor-pointer ${currentSlideStories === 0 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-white flex items-center justify-center`}
                                style={{ borderColor: currentSlideStories === 0 ? '#A6A6A6' : '#2A2A2A' }}
                            >
                                <KeyboardArrowLeftIcon className={` ${currentSlideStories === 0 ? 'text-[#A6A6A6]' : 'text-customBlack'}`} />
                            </Box>
                            <Box
                                onClick={handleNextStories}
                                className={`ml-4 cursor-pointer ${currentSlideStories === totalSlidesStories - 1 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-#2A2A2A flex items-center justify-center`}
                                style={{ borderColor: currentSlide1 === totalSlides - 1 ? '#A6A6A6' : '#2A2A2A' }}
                            >
                                <KeyboardArrowRightIcon className={`${currentSlideStories === totalSlidesStories - 1 ? 'text-[#A6A6A6]' : 'text-customBlack'}`} />
                            </Box>
                        </Box>
                    </Box>

                    <Box className='lg:pt-80px pt-8  '>

                        <Carousel className='hidden lg:block'
                            selectedItem={currentSlideStories}
                            onChange={(index) => setCurrentSlidestories(index)}
                            showThumbs={false}
                            showStatus={false}
                            showArrows={false}
                            interval={5000}
                            showIndicators={false}
                            autoPlay
                            infiniteLoop
                            emulateTouch // Ensures smooth touch behavior
                            swipeable // Allows swipe gestures on mobile devices
                            width="100%"
                        >

                            {/* Different view for mobile and desktop */}

                            <AhsStories topHead="I am very grateful to the brotherhood who held our 
                       hands when there was no other support." belowHead="Sanam"
                                gridImage={AhsDoctor1}
                                btnCondt="yes"
                                gridColor='DustyRose'
                                btnText='Health'
                                textColor='text-AhsPrimary'
                                btnColor='AhsPrimary' />



                        </Carousel>


                        {/* For mobile */}

                        <Carousel className='lg:hidden '
                            selectedItem={currentSlideStories}
                            onChange={(index) => setCurrentSlidestories(index)}
                            showThumbs={false}
                            showStatus={false}
                            showArrows={false}
                            interval={5000}
                            showIndicators={false}
                            autoPlay
                            infiniteLoop
                            emulateTouch // Ensures smooth touch behavior
                            swipeable // Allows swipe gestures on mobile devices
                            width="100%"
                        >



                            <Box className='rounded-[30px] overflow-hidden'>
                                <AhsStories gridImage={AhsDoctor1} />
                                <AhsStories topHead="I am very grateful to the brotherhood who held our 
                       hands when there was no other support." belowHead="Sanam"
                                    btnCondt="yes"
                                    gridColor='DustyRose'
                                    btnText='Health'
                                    textColor='text-AhsPrimary'
                                    btnColor='AhsPrimary'

                                />



                            </Box>






                        </Carousel>

                        {/* Navigation Buttons */}
                        <Box className="lg:hidden flex justify-center pt-8">
                            <button
                                onClick={() => handleChangeSlideStories(0)}
                                className={` w-[48px] h-[4px]  rounded-full focus:outline-none ${currentSlideStories === 0 ? 'bg-topGreen' : 'bg-topAlmond'
                                    }`}
                            ></button>
                            <button
                                onClick={() => handleChangeSlideStories(1)}
                                className={`w-[48px] h-[4px]  rounded-full focus:outline-none  ${currentSlideStories === 1 ? 'bg-topGreen' : 'bg-topAlmond'
                                    }`}
                            ></button>
                            <button
                                onClick={() => handleChangeSlideStories(2)}
                                className={`w-[48px] h-[4px] rounded-full   ${setCurrentSlidestories === 2 ? 'bg-topGreen' : 'bg-topAlmond'
                                    }`}
                            ></button>


                        </Box>

                    </Box>




                </Box>
            </Box>
        </Box>


    )

}

export default Ahs