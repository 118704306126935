import React, { useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import backgroundImage from '../pictures/Active_facility.png';
import Swal from 'sweetalert2';
import Loader from '../Loader';
import { uploadJob} from '../Actions/JobActions';
import CustomButton from '../ReusableComponents/CustomButton';
import { Box } from '@material-ui/core';

const JobUpload = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [jobType, setJobType] = useState('');
    const [jobLocation, setJobLocation] = useState('');
    const [jobGoogleLink, setJobGoogleLink] = useState('');
    const [loading, setLoading] = useState(false);
    const {user}=useSelector(state=>state.user)

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);



        try {
            await dispatch(uploadJob(title, description, jobType, jobLocation, jobGoogleLink, user.name));
            Swal.fire({
                title: 'Success!',
                text: 'Job posted successfully!',
                icon: 'success',
                confirmButtonText: 'Okay',
            });

            setTitle('');
            setDescription('');
            setJobType('');
            setJobLocation('');
            setJobGoogleLink('');
        } catch (error) {
            console.error('Error uploading job:', error);
            Swal.fire({
                title: 'Error!',
                text: 'Error uploading job. Please try again.',
                icon: 'error',
                confirmButtonText: 'Okay',
            });
        } finally {
            setLoading(false);
        }
    };





    return (
        <>
            <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
                <div className="flex flex-col md:flex-row w-full max-w-4xl bg-white rounded-lg shadow-lg overflow-hidden">
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="w-full md:w-1/2 p-8">
                                <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Upload New Job</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="title">Title</label>
                                        <input
                                            type="text"
                                            id="title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="description">Description</label>
                                        <textarea
                                            id="description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        ></textarea>
                                    </div>


                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="jobType">Job Type</label>
                                        <select
                                            id="jobType"
                                            value={jobType}
                                            onChange={(e) => setJobType(e.target.value)}
                                            className="w-full p-3 pr-10 bg-gray-50 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all appearance-none"
                                            required
                                        >
                                            <option value="" disabled>Select Job Type</option>
                                            <option value="Full Time">Full Time</option>
                                            <option value="Part Time">Part Time</option>
                                            <option value="Contractual">Contractual</option>
                                        </select>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="jobLocation">Job Location</label>
                                        <select
                                            id="jobLocation"
                                            value={jobLocation}
                                            onChange={(e) => setJobLocation(e.target.value)}
                                            className="w-full p-3 pr-10 bg-gray-50 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all appearance-none"
                                            required
                                        >
                                            <option value="" disabled>Select Job Location</option>
                                            <option value="OnSite">OnSite</option>
                                            <option value="Remote">Remote</option>
                                            <option value="Hybrid">Hybrid</option>
                                        </select>
                                    </div>



                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="description">Google Link</label>
                                        <textarea
                                            id="googleLink"
                                            value={jobGoogleLink}
                                            onChange={(e) => setJobGoogleLink(e.target.value)}
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        ></textarea>
                                    </div>


                                    <button type="submit" className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
                                        Upload Job</button>
                                </form>
                                <Box className='flex justify-center pt-10'>
                                    <CustomButton btnURL='/open/jobs' btnText='check jobs' btnBackground='customBlack' />
                                </Box>
                            </div>

                            <div
                                className="hidden md:block md:w-1/2 bg-cover bg-center"
                                style={{ backgroundImage: `url(${backgroundImage})` }}
                            ></div>
                        </>
                    )}
                </div>


            </div>

        </>
    )
};

export default JobUpload;
