import React, { Fragment, useState, useEffect } from "react";
import { login, clearErrors, loadUser } from "../Actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from 'react-alert';
import LockIcon from '@mui/icons-material/Lock';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import LoadingSpinner from "../Loader";
import { Box } from "@material-ui/core";
import Store from "../Store";
import Swal from "sweetalert2";


const Login = () => {
    const alert = useAlert();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [loading, setLoading] = useState(false);


    const loginSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        try{
            dispatch(login(loginEmail, loginPassword));
            Swal.fire({
                title: 'Success!',
                text: 'Logged in  successfully!',
                icon: 'success',
                confirmButtonText: 'Okay',
            });

            navigate('/dashboard');


        }
        catch (error) {
            console.error('Error logging in:', error);
            Swal.fire({
                title: 'Error!',
                text: 'Error . Please try again.',
                icon: 'error',
                confirmButtonText: 'Okay',
            });
        } finally {
            setLoading(false);
        }
        
    };

  
    return (
        <Fragment>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <Box className="flex items-center justify-center min-h-screen bg-gray-100">
                    <div className="p-8 bg-white rounded-lg shadow-lg">
                        <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">Login</h2>
                        <form onSubmit={loginSubmit} className="space-y-6">
                            <div className="relative">
                                <EmailIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                <input
                                    type="email"
                                    placeholder="Enter email"
                                    value={loginEmail}
                                    onChange={(e) => setLoginEmail(e.target.value)}
                                    className="w-full pl-10 p-3 bg-gray-50 text-gray-700 border border-gray-300 rounded-md outline-none focus:border-blue-500 focus:bg-white transition-all"
                                />
                            </div>
                            <div className="relative">
                                <LockIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                <input
                                    type="password"
                                    placeholder="Enter password"
                                    value={loginPassword}
                                    onChange={(e) => setLoginPassword(e.target.value)}
                                    className="w-full pl-10 p-3 bg-gray-50 text-gray-700 border border-gray-300 rounded-md outline-none focus:border-blue-500 focus:bg-white transition-all"
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full p-3 text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-all flex items-center justify-center"
                            >
                                <SendIcon className="mr-2" /> Login
                            </button>
                            <p className="text-center text-gray-600 mt-4">
                                New to the website?{" "}
                                <Link to="/register" className="text-blue-500 hover:underline">
                                    Register
                                </Link>
                            </p>
                        </form>
                    </div>
                </Box>
            )}
        </Fragment>
    );
};

export default Login;
