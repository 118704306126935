import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    return (
        <div className="flex h-screen bg-gray-50">
            {/* Sidebar */}
            <aside className="w-64 bg-customBlack text-white shadow-lg">
                <div className="p-8">
                    <h2 className="text-3xl font-bold mb-8">Admin Dashboard</h2>
                    <nav className="mt-6">
                        <ul className="space-y-6">
                            <li>
                                <Link
                                    to="/createBlog"
                                    className="text-lg font-semibold hover:text-blue-300 transition-colors duration-300"
                                >
                                    Create Blog
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/manage-blogs"
                                    className="text-lg font-semibold hover:text-blue-300 transition-colors duration-300"
                                >
                                    Manage Blogs
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/add/job"
                                    className="text-lg font-semibold hover:text-blue-300 transition-colors duration-300"
                                >
                                    Post Jobs
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/admin/jobs"
                                    className="text-lg font-semibold hover:text-blue-300 transition-colors duration-300"
                                >
                                    All Jobs
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>

            {/* Main Content */}
            <main className="flex-1 p-8 overflow-auto bg-white">
                <header className="mb-8">
                    <h1 className="text-4xl font-bold text-gray-800">Welcome to the Dashboard</h1>
                    <p className="mt-2 text-lg text-gray-600">Manage blogs, jobs, and more from this centralized hub.</p>
                </header>

                {/* Card Section */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Create Blog</h3>
                        <p className="text-gray-600">Create new blogs with ease, adding content, images, and more.</p>
                        <Link
                            to="/createBlog"
                            className="mt-4 inline-block text-blue-500 hover:text-blue-700 transition-colors duration-300"
                        >
                            Go to Create Blog
                        </Link>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Manage Blogs</h3>
                        <p className="text-gray-600">Edit and manage existing blogs efficiently.</p>
                        <Link
                            to="/manage-blogs"
                            className="mt-4 inline-block text-blue-500 hover:text-blue-700 transition-colors duration-300"
                        >
                            Go to Manage Blogs
                        </Link>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Post Jobs</h3>
                        <p className="text-gray-600">Easily post job opportunities for potential candidates.</p>
                        <Link
                            to="/add/job"
                            className="mt-4 inline-block text-blue-500 hover:text-blue-700 transition-colors duration-300"
                        >
                            Go to Post Jobs
                        </Link>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">All Jobs</h3>
                        <p className="text-gray-600">View and manage all posted job listings.</p>
                        <Link
                            to="/admin/jobs"
                            className="mt-4 inline-block text-blue-500 hover:text-blue-700 transition-colors duration-300"
                        >
                            Go to All Jobs
                        </Link>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Dashboard;
