import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box } from '@material-ui/core';
import news1 from "./pictures/news1-image.png";
import news2 from "./pictures/news2-image.png";
import news3 from "./pictures/news3-image.png";
import Founder from "./pictures/founder-msg.png"
import aboutAk from "./pictures/about-ak.png";
import FounderMobile from "./pictures/founder-mobile.png";
import NumberPattern from "./pictures/home-about-pattern.png"
import { useMediaQuery, useTheme } from '@material-ui/core';
import CustomButton from './ReusableComponents/CustomButton';
import CustomTypography from './CustomTypography';
import MediaCard from './MediaCard';
import CustomCarousel from './CustomCarasouel';


const cards = [
    <MediaCard cardImg={news1}
        cardTitle="MICROFINANCE"
        cardDesc="Akhuwat's Microfinance Impact: Stories of Transformation"
        cardDate="June 8, 2024"
        titleBackColor="simpleGreen"
        titleColor="customBlack"
    />,

    <MediaCard cardImg={news2}
        cardTitle="EDUCATION"
        cardDesc="Akhuwat Education initiated the IT skills program 2024-25"
        cardDate="June 8, 2024"
        titleBackColor="AesHead"
        titleColor="white"
    />,

    <MediaCard cardImg={news3}
        cardTitle="HEALTH"
        cardDesc="Akhuwat opens it’s 12th healthcare center in Kasur"
        cardDate="June 8, 2024"
        titleBackColor="AhsPrimary"
        titleColor="white"
    />,
]

export default function LatestNews() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box>






            {/* About Akhuwat section */}

            <Box className="bg-LightBrown  h-auto lg:py-140px 
            lg:px-[144px]
             md:py-16 md:px-10 py-10 px-4 "
                style={{
                    backgroundImage: !isMobile ? `url(${NumberPattern})` : 'none',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right bottom'
                }}
            >

                <Box className='max-w-screen-2xl mx-auto  grid lg:grid-cols-2  grid-cols-1
                lg:gap-10 gap-5'>  {/** implemented max width */}


                    <Box className="h-auto w-auto rounded-[16px] overflow-hidden">
                        <img src={aboutAk} alt="image" className='lg:h-[550px] md:h-[391px] w-full h-auto object-cover '></img>
                    </Box>
                    <Box className="lg:pt-[50px] pt-4">
                        <Box >
                            <CustomTypography as="h2" variant="h2" color="text-blackColor" weightFont="font-bold"
                                alignment='lg:text-left text-center'>
                                About Akhuwat
                            </CustomTypography>
                        </Box>
                        <Box className="mt-[30px] mb-[50px]">

                            <CustomTypography className='lg:text-left text-center' as="p" variant="p3" color="text-customBlack" weightFont="font-normal">
                                Since 2001, Akhuwat has been working for poverty alleviation through
                                its various programs and projects and has disbursed PKR 200 billion
                                in interest-free loans among 6 million beneficiaries.<br></br>
                                <br></br> Akhuwat also
                                provides fee-free education to deserving students and empowers Khwajasiras
                                (transgenders) by giving them financial and medical assistance.
                            </CustomTypography>

                        </Box>
                        <Box className='flex justify-center lg:justify-start'>
                            <CustomButton btnText="Learn more" btnURL="/about"
                                btnColor="white" btnBackground="customBlack" />
                        </Box>




                    </Box>
                </Box>

            </Box>

            {/**Founder msg */}

            <Box className="relative w-full  lg:px-16 lg:py-90px py-10 px-4 h-auto
            max-w-screen-2xl mx-auto">

                <CustomTypography as="h2" variant="h21" color="text-blackColor"
                    weightFont="font-bold" alignment="text-center" >

                    Message from the Founder

                </CustomTypography>
                <Box className="hidden  lg:block lg:mt-[50px] relative lg:h-[500px]  
                bg-cover bg-center rounded-[20px] w-full overflow-hidden rounded-[20px]"
                    style={{ backgroundImage: `url(${Founder})`, clipPath: 'inset(0 round 20px)' }}>
                    <Box className='hidden lg:grid grid-cols-2'>

                        <Box>

                        </Box>
                        <Box className='py-16 pr-[60px]'>


                            <CustomTypography as="p" variant="p1" color="text-white"
                                weightFont="font-semibold"  >
                                “People deserve to live with dignity and get support in transforming their lives.
                                Akhuwat aims to play its role in empowering people and paving sustainable pathways
                                out of poverty through microfinance and education.”
                            </CustomTypography>
                        </Box>

                    </Box>


                </Box>

                <Box className="flex justify-center items-center lg:hidden mt-4 relative rounded-[20px]  mx-auto" >
                    <img src={FounderMobile} alt="Founder img" className='w-full h-full object-cover' />
                </Box>

                <Box className='lg:hidden mt-4'>


                    <CustomTypography as="p" variant="p1" color="text-blackColor"
                        weightFont="font-semibold" alignment="lg:text-left text-center"  >
                        “People deserve to live with dignity and get support in transforming their lives.
                        Akhuwat aims to play its role in empowering people and paving sustainable pathways
                        out of poverty through microfinance and education.”
                    </CustomTypography>


                </Box>

                <Box className='grid lg:grid-cols-2 grid-cols-1'>
                    <Box>

                    </Box>
                    <Box className='lg:mt-[-80px] mt-4 grid lg:grid-cols-2 lg:gap-[101px] gap-4 grid-cols-1'>
                        <Box className='lg:w-[410px] w-auto'>


                            <CustomTypography as="p" variant="p2" color="text-customBlack"
                                weightFont="font-normal" alignment="lg:text-left text-center"  >
                                Dr. Muhammad Amjad Saqib,(SI, HI) <br />
                                Founder & Chairman, Akhuwat
                            </CustomTypography>
                        </Box>

                        <Box className="mt-4 lg:mt-[1px] flex justify-center lg:justify-end">
                            <CustomButton btnText="View profile" btnURL="/aes"
                                btnColor="white" btnBackground="customBlack" />
                        </Box>


                    </Box>
                </Box>


            </Box>



            {/**News section */}
             <Box className='bg-customGreen'>
            <Box className="  lg:px-16 lg:py-[140px] py-10 px-4 h-auto">
                <Box className='max-w-screen-2xl mx-auto'> {/* implemented max width */}
                    <Box className="grid lg:grid-cols-2 grid-cols-1 gap-8 lg:mb-12 mb-6">
                        <Box className='lg:w-[874px]'>

                            <CustomTypography as="h2" variant="h21" color="text-white"
                                weightFont="font-semibold" alignment="lg:text-left text-center"  >
                                Media
                            </CustomTypography>

                            <CustomTypography className='lg:pt-6 mt-[14px]' as="p" variant="p2" color="text-white"
                                weightFont="font-normal" alignment="lg:text-left text-center"  >
                                Each month, Akhuwat extends its vision of poverty-alleviation and achieves
                                new milestones under its various programs. Here you can find news, blogs, and
                                updates about Akhuwat’s various programs and projects.
                            </CustomTypography>
                        </Box>
                        <Box className="hidden lg:flex justify-center items-center lg:justify-end lg:items-start">
                            <CustomButton btnText="View all" btnURL="/aim"
                                btnColor="customBlack" btnBackground="white" />
                        </Box>
                    </Box>


                    <Box className="hidden lg:grid grid-cols-1 lg:grid-cols-3 gap-5">
                        {cards}
                    </Box>

                </Box>
            </Box>

            {/* Card section for  mobile view */}




            <Box className="lg:hidden py-10 md:pl-4 md:pr-0 pl-4 pr-4  ">

                <CustomCarousel slides={cards} slideWidth={{
                    lg: 329,
                    md: 329,
                    sm: 329
                }} gap={20}
                    ArrowPosition='bottom'
                    ArrowActiveColor='white'
                    ArrowInActiveColor='#A6A6A6'
                    ArrowAligned='end'

                />


                            {/* View all news Link in mobile view */}
            <Box className="mt-8 lg:hidden flex justify-center">
                <CustomButton btnText="View all" btnURL="/aim"
                    btnColor="customBlack" btnBackground="white" />
            </Box>


            </Box>


            </Box>

        </Box>
    );
}
