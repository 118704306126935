import React, { Fragment, useState, useEffect } from "react";
import { register, clearErrors } from "../Actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Loader";
import { useAlert } from 'react-alert';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from "react-router-dom";

const Register = () => { 
    const alert = useAlert();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { error, loading, isAuthenticated } = useSelector(state => state.newUser);
    const [registerName, setRegisterName] = useState("");
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");

    const loginSubmit = (e) => {
        e.preventDefault();
        dispatch(register(registerName, registerEmail, registerPassword));
    };

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        } else if (isAuthenticated) {
            alert.success('You signed up successfully!');
            navigate("/login");
        }
    }, [dispatch, error, navigate, isAuthenticated, alert]);

    return (
        <Fragment>
            {loading ? <LoadingSpinner /> : (
                <div className="flex items-center justify-center min-h-screen bg-gray-100">
                    <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
                        <h2 className="text-2xl font-bold text-center text-gray-800">Register</h2>
                        <form className="space-y-4" onSubmit={loginSubmit}>
                            <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-2">
                                <PersonIcon className="text-gray-400" />
                                <input 
                                    type="text" 
                                    placeholder="Enter name" 
                                    value={registerName} 
                                    onChange={(e) => setRegisterName(e.target.value)} 
                                    className="w-full outline-none focus:ring-2 focus:ring-indigo-500" 
                                />
                            </div>
                            
                            <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-2">
                                <EmailIcon className="text-gray-400" />
                                <input 
                                    type="email" 
                                    placeholder="Enter email" 
                                    value={registerEmail} 
                                    onChange={(e) => setRegisterEmail(e.target.value)} 
                                    className="w-full outline-none focus:ring-2 focus:ring-indigo-500" 
                                />
                            </div>

                            <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-2">
                                <LockIcon className="text-gray-400" />
                                <input 
                                    type="password" 
                                    placeholder="Enter password" 
                                    value={registerPassword} 
                                    onChange={(e) => setRegisterPassword(e.target.value)} 
                                    className="w-full outline-none focus:ring-2 focus:ring-indigo-500" 
                                />
                            </div>

                            <Button 
                                type="submit" 
                                disabled={loading} 
                                variant="contained" 
                                endIcon={<SendIcon />} 
                                className="w-full py-2 mt-4 text-white bg-indigo-600 hover:bg-indigo-700"
                            >
                                Register
                            </Button>
                            
                            <p className="text-center text-gray-500">
                                Already Registered? <Link to="/login" className="text-indigo-600 hover:underline">Login</Link>
                            </p>
                        </form>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default Register;
