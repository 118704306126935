import { Box } from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography";
import Banner from "../pictures/Life_banner.png";
import Card1 from "../pictures/Life_card1.png";
import Card2 from "../pictures/Life_card2.png";
import Card3 from "../pictures/Life_card3.png";
import icon1 from "../pictures/Life_inter_icon1.png";
import icon2 from "../pictures/Life_icon2.png";
import icon3 from "../pictures/Life_icon3.png";
import BottomPic from "../pictures/Life_footer.jfif";
import CustomButton from "../ReusableComponents/CustomButton";
import { Search } from "@material-ui/icons";



const LifeAtAkhuwat = () => {
    return (

        <Box>

            {/* Banner section */}

            <Box className="max-w-screen-2xl mx-auto lg:pt-6 lg:pb-80px lg:px-120px bg-white md:px-10 md:pt-60px m:pb-60px px-4 pt-6 pb-6 ">

                <CustomTypography as="h2" variant="h21" alignment="text-center" color="text-blackColor" weightFont="font-bold">
                    Life at Akhuwat
                </CustomTypography>


                <img src={Banner} alt="banner" className="pt-5 object-cover lg:h-[435px] md:h-[335px] h-[240px]
                rounded-[15px] overflow-hidden"/>

            </Box>

            {/** card section */}

            <Box className="lg:px-120px lg:py-90px md:py-60px md:px-10 py-6 px-4 bg-topGreen">

                <Box className="max-w-screen-2xl mx-auto grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-5">

                    <Box>
                        <img src={Card1} alt="cad1" />

                        <CustomTypography className="pt-5" as="p" variant="p1" alignment="text-left" color="text-white" weightFont="font-semibold">
                            Lorem ipsum dolor sit
                        </CustomTypography>



                        <CustomTypography className="pt-4" as="p" variant="p3" alignment="text-left" color="text-customGray" weightFont="font-normal">
                            Lorem ipsum dolor sit amet consectetur. Sit dignissim neque tortor amet scelerisque
                            viverra bibendum ac odio. At massa eu urna ut.
                        </CustomTypography>

                    </Box>



                    <Box>
                        <img src={Card2} alt="cad1" />

                        <CustomTypography className="pt-5" as="p" variant="p1" alignment="text-left" color="text-white" weightFont="font-semibold">
                            Lorem ipsum dolor sit
                        </CustomTypography>



                        <CustomTypography className="pt-4" as="p" variant="p3" alignment="text-left" color="text-customGray" weightFont="font-normal">
                            Lorem ipsum dolor sit amet consectetur. Sit dignissim neque tortor amet scelerisque
                            viverra bibendum ac odio. At massa eu urna ut.
                        </CustomTypography>

                    </Box>





                    <Box className="md:hidden block lg:block">
                        <img src={Card3} alt="cad3" />

                        <CustomTypography className="pt-5" as="p" variant="p1" alignment="text-left" color="text-white" weightFont="font-semibold">
                            Lorem ipsum dolor sit
                        </CustomTypography>



                        <CustomTypography className="pt-4" as="p" variant="p3" alignment="text-left" color="text-customGray" weightFont="font-normal">
                            Lorem ipsum dolor sit amet consectetur. Sit dignissim neque tortor amet scelerisque
                            viverra bibendum ac odio. At massa eu urna ut.
                        </CustomTypography>

                    </Box>


                    <Box className="md:block hidden lg:hidden ">

                        <CustomTypography as="p" variant="p1" alignment="text-left" color="text-white" weightFont="font-semibold">
                            Lorem ipsum dolor sit
                        </CustomTypography>



                        <CustomTypography className="pt-4" as="p" variant="p3" alignment="text-left" color="text-customGray" weightFont="font-normal">
                            Lorem ipsum dolor sit amet consectetur. Sit dignissim neque tortor amet scelerisque
                            viverra bibendum ac odio. At massa eu urna ut.
                        </CustomTypography>

                    </Box>

                    <Box className="lg:hidden md:block hidden">
                        <img src={Card3} alt="cad3" />
                    </Box>


                </Box>

            </Box>

            {/* Internship section */}

               <Box className="bg-customGreen">
            <Box className="max-w-screen-2xl mx-auto lg:px-120px lg:py-90px md:py-60px md:px-10 py-6 px-4 ">

                <Box className="flex lg:flex-row md:flex-row flex-col justify-between">
                    <CustomTypography as="h2" variant="h2" alignment="lg:text-left md:text-left text-center" color="text-white" weightFont="font-bold">
                        Internships
                    </CustomTypography>

                    <Box className="flex lg:justify-end md:justify-end  justify-center lg:pt-0 md:pt-0 pt-3">
                        <CustomButton btnText="Explore internships" btnURL="/aes" btnColor="customBlack"
                            btnBackground="white" />
                    </Box>

                </Box>

                <Box className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 pt-10">

                    <Box className="rounded-[20px] p-6 border border-MintGreen">


                        <Box className="flex justify-center">
                            <img src={icon1} alt="con1" />
                        </Box>

                        <CustomTypography as="p" variant="p2" alignment=" text-center" color="text-MintGreen" weightFont="font-semibold">
                            Lorem ipsum dolor sit
                        </CustomTypography>

                        <CustomTypography className="pt-[10px]" as="p" variant="p3" alignment=" text-center" color="text-white" weightFont="font-normal">
                            Lorem ipsum dolor sit amet consectetur. Sit dignissim neque tortor amet
                            scelerisque viverra bibendum ac odio. At massa eu urna ut.
                        </CustomTypography>



                    </Box>


                    <Box className="rounded-[20px] p-6 border border-MintGreen">

                        <Box className="flex justify-center">
                            <img src={icon2} alt="con1" />
                        </Box>


                        <CustomTypography as="p" variant="p2" alignment=" text-center" color="text-MintGreen" weightFont="font-semibold">
                            Lorem ipsum dolor sit
                        </CustomTypography>

                        <CustomTypography className="pt-[10px]" as="p" variant="p3" alignment=" text-center" color="text-white" weightFont="font-normal">
                            Lorem ipsum dolor sit amet consectetur. Sit dignissim neque tortor amet
                            scelerisque viverra bibendum ac odio. At massa eu urna ut.
                        </CustomTypography>



                    </Box>


                    <Box className="rounded-[20px] p-6 border border-MintGreen">


                        <Box className="flex justify-center">
                            <img src={icon3} alt="con1" />
                        </Box>

                        <CustomTypography as="p" variant="p2" alignment=" text-center" color="text-MintGreen" weightFont="font-semibold">
                            Lorem ipsum dolor sit
                        </CustomTypography>

                        <CustomTypography className="pt-[10px]" as="p" variant="p3" alignment=" text-center" color="text-white" weightFont="font-normal">
                            Lorem ipsum dolor sit amet consectetur. Sit dignissim neque tortor amet
                            scelerisque viverra bibendum ac odio. At massa eu urna ut.
                        </CustomTypography>


                        </Box>
                    </Box>
                </Box>




            </Box>


            <Box className="max-w-screen-2xl mx-auto  lg:px-120px lg:py-90px md:py-60px md:px-10 py-6 px-4 bg-white"

            >

                <Box
                    className="lg:h-[400px] md:h-[297px] h-[229px] bg-cover bg-center relative rounded-[24px] flex flex-col justify-center items-center gap-[20px]"
                    style={{
                        backgroundImage: `url(${BottomPic})`,
                    }}
                >
                    <CustomTypography as="h2" variant="h2" alignment="text-center" color="text-white" weightFont="font-bold">
                        Ready to join our team?
                    </CustomTypography>

                    <Box className="flex items-center bg-white rounded-[70px] py-[6px] px-3
                    lg:w-[357px] md:w-[357px] w-auto">
                        <input
                            type="text"
                            placeholder="Start your search"
                            className="bg-transparent outline-none flex-grow text-center text-customBlack
                            font-manrope font-normal"
                        />
                        <Box className="bg-green-500 p-3 rounded-full flex items-center justify-center">
                            <Search style={{ color: "white" }} />
                        </Box>
                    </Box>
                </Box>






            </Box>

        </Box>
    )
}

export default LifeAtAkhuwat;