import React from "react";
import { Box } from "@material-ui/core";

const HeroDonate = () => {


    return (

        <Box className="bg-white  lg:p-[31.36px] p-4   rounded-[19.6px]  w-auto lg:h-[436.32px] h-auto lg:w-[409px] w-auto ">
            <h1 className="font-manrope font-bold text-fluid-body
             fluid-line-body text-center text-blackColor">Donate</h1>
            <form>
                {/* Two fields for Desktop only */}
                <Box className="lg:grid grid-cols-2 hidden lg:pt-[31.36px] justify-center">
                    <Box className="relative z-10 -mr-3">
                        <input
                            type="radio"
                            name="option"
                            id="option1"
                            className="hidden"
                        />
                        <label
                            htmlFor="option1"
                            className="bg-primaryLight rounded-full 
                h-[47.13px] p-[13.07px] hover:bg-customBlack cursor-pointer 
                flex items-center justify-center text-center text-blackColor
                font-manrope text-[15.68px] font-normal leading-[21.42px] hover:text-white"
                        >
                            Monthly
                        </label>
                    </Box>
                    <Box className="relative -ml-3 z-20"> {/* Added negative margin here */}
                        <input
                            type="radio"
                            name="option"
                            id="option2"
                            className="hidden"
                        />
                        <label
                            htmlFor="option2"
                            className="bg-customBlack rounded-full 
                h-[47.13px] p-[13.07px]  cursor-pointer 
                flex items-center justify-center text-center text-white
                font-manrope text-[15.68px] font-normal leading-[21.42px] hover:text-white"
                        >
                            One time
                        </label>
                    </Box>
                </Box>


                <Box className="grid grid-cols-2  lg:gap-[13.07px] gap-3  lg:0 pt-4 justify-center">
                    <Box className="lg:hidden">
                        <input
                            type="radio"
                            name="option"
                            id="option1"
                            className="hidden"
                        />
                        <label
                            htmlFor="option1"
                            className="bg-primaryLight rounded-full 
                             h-[47.13px] p-[13.07px]  hover:bg-customBlack  cursor-pointer 
                            flex items-center justify-center text-center text-blackColor
                font-manrope text-[15.68px]  font-normal  leading-[21.42px] hover:text-white"
                        >
                            Monthly
                        </label>
                    </Box>
                    <Box className="lg:hidden">
                        <input
                            type="radio"
                            name="option"
                            id="option2"
                            className="hidden"
                        />
                        <label
                            htmlFor="option2"
                            className="bg-primaryLight rounded-full 
                            h-[47.13px] p-[13.07px]  hover:bg-customBlack  cursor-pointer 
                           flex items-center justify-center text-center text-blackColor
               font-manrope text-[15.68px]  font-normal  leading-[21.42px] hover:text-white"
                        >
                            One time
                        </label>
                    </Box>
                    <Box>
                        <input
                            type="radio"
                            name="option"
                            id="option3"
                            className="hidden"
                        />
                        <label
                            htmlFor="option3"
                            className="bg-primaryLight rounded-full 
                            h-[47.13px] p-[13.07px]  hover:bg-customBlack  cursor-pointer 
                           flex items-center justify-center text-center text-blackColor
               font-manrope text-[15.68px]  font-normal  leading-[21.42px] hover:text-white"
                        >
                            Rs. 1000
                        </label>
                    </Box>
                    <Box >
                        <input
                            type="radio"
                            name="option"
                            id="option4"
                            className="hidden"
                        />
                        <label
                            htmlFor="option4"
                            className="bg-primaryLight rounded-full 
                            h-[47.13px] p-[13.07px]  hover:bg-customBlack  cursor-pointer 
                           flex items-center justify-center text-center text-blackColor
               font-manrope text-[15.68px]  font-normal  leading-[21.42px] hover:text-white"
                        >
                            Rs. 2500
                        </label>
                    </Box>
                    <Box >
                        <input
                            type="radio"
                            name="option"
                            id="option5"
                            className="hidden"
                        />
                        <label
                            htmlFor="option5"
                            className="bg-primaryLight rounded-full 
                            h-[47.13px] p-[13.07px]  hover:bg-customBlack  cursor-pointer 
                           flex items-center justify-center text-center text-blackColor
               font-manrope text-[15.68px]  font-normal  leading-[21.42px] hover:text-white"
                        >
                            Rs. 5000
                        </label>
                    </Box>
                    <Box>
                        <input
                            type="radio"
                            name="option"
                            id="option5"
                            className="hidden"
                        />
                        <label
                            htmlFor="option5"
                            className="bg-primaryLight rounded-full 
                            h-[47.13px] p-[13.07px]  hover:bg-customBlack  cursor-pointer 
                           flex items-center justify-center text-center text-blackColor
               font-manrope text-[15.68px]  font-normal  leading-[21.42px] hover:text-white"
                        >
                            Rs. 10,000
                        </label>
                    </Box>

                    <Box className="col-span-2">
                        <label
                            htmlFor="option5"
                            className="bg-primaryLight rounded-full h-[47.13px] p-[13.07px] flex items-center justify-between text-center text-blackColor w-full"
                        >
                            <span className="w-[50%] font-manrope text-[15.68px] font-normal leading-[21.42px] text-blackColor">
                                Custom amount
                            </span>

                            <div className="relative w-[50%] pr-[60px]">
                                <input
                                    type="text"
                                    name="option"
                                    id="option5"
                                    placeholder="Rs"
                                    className="w-full bg-transparent outline-none text-center font-manrope text-[15.68px] font-normal leading-[21.42px] text-blackColor"
                                />
                            </div>
                        </label>
                    </Box>




                    <Box className="col-span-2">
                        <button
                            type="submit"
                            className="w-full lg:h-[51.13px] h-[60px] p-[13.07px]  rounded-full bg-topGreen text-white
                              font-manrope  text-[18.29px] font-semibold 
                             leading-[24.99px] flex items-center justify-center hover:text-white hover:bg-customBlack"
                        >
                            Donate
                        </button>
                    </Box>

                </Box>
            </form>
        </Box>

    )

}

export default HeroDonate