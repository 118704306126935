import React, { useState, useEffect } from "react";
import { useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Box, useMediaQuery, useTheme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import pic from "./pictures/frame-logo.png";
import SearchIcon from '@mui/icons-material/Search';
import Submenu from "./Submenu";
import SubMenuImg1 from "./pictures/Sub_menu_image1.png"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Custom styles for the AppBar, Button, and logo
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#ffffff',
    color: '#000000',
    width: '100%',
    height: '140px',
    borderBottom: '1px solid #F2F4F7',
    boxShadow: 'none',
    opacity: 1,
    fontFamily: 'Manrope',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    paddingLeft: '64px',
    paddingRight: '64px',
    [theme.breakpoints.down('sm')]: {
      height: '61px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%', // Ensures the toolbar takes full height of AppBar
    padding: '0 20px', // Add padding if needed
  },
  drawer: {
    width: 250,
  },
  logo: {
    width: '61px', // Logo width
    height: '100px', // Logo height
    gap: '0px', // No gap
    opacity: 1, // Fully opaque
    marginRight: theme.spacing(2), // Adjusted margin for better alignment
    [theme.breakpoints.down('sm')]: {
      width: '36px',
      height: '45px',
    },
  },
  buttonPrice: {
    backgroundColor: '#2A2A2A', // Custom background color
    width: '128px',
    height: '59px',
    gap: '8px',
    borderRadius: '80px',
    color: '#ffffff', // Text color
    opacity: 1,
    fontFamily: 'Manrope', // Font family
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '27px',
    textAlign: 'left',
    textTransform: 'none', // Ensure text is not transformed to uppercase
    textDecoration: 'none', // Remove underline from links
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '96px',
      height: '40px',
      fontWeight: 500,
      fontSize: '14px',
    },
  },
  menuItem: {
    color: '#737373', // Default text color
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    textTransform: 'none',
    marginRight: '32px',
    textDecoration: 'none',
    cursor: 'pointer',
    listStyleType: 'none',
    transition: 'color 0.3s ease', // Smooth transition for color change

    '&:hover': {
      color: 'green', // Hover color
    },
    '&.active': {
      color: 'red', // Active color
    },
  },

  selectedMenu: {
    color: 'green',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    textTransform: 'none',
    marginRight: '32px',
    textDecoration: 'none',
    cursor: 'pointer',
  },

  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px', // Gap between menu items and buttons
  },
  mobileMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end', // Align to the right
    width: '100%', // Take full width
    gap: '12px', // Gap between the donate button and menu icon
  },
}));

function Nav() {

  const location = useLocation();//for checking the rendering


  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeMenu, setActiveMenu] = useState(null);

  const [clickedMenuItem, setClickedMenuItem] = useState('Home');//for setting the active color to desktop meny

  const handleMenuColor = (menuName) => {
    setClickedMenuItem(menuName);
  }



  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };


  useEffect(() => {
    // Reset active menu whenever the route changes
    setActiveMenu(null);
  }, [location]);

  // Extended submenu data structure for Programs and About


  const submenuData = {
    programs: {
      title: "Programs",
      desc: "Akhuwat runs a range of programs, which aim to alleviate poverty and bring systemic change in society.",
      links: [
        {
          label: "Akhuwat Islamic Microfinance",
          path: "/aim", // New top-level path
          items: [
            { label: "Akhuwat Islamic Microfinance", path: "/aim" },
            { label: "Loan Products", path: "/programs/loan/products" },
            { label: "Loan Process", path: "/programs/loan/process" },
            { label: "Our Stories of Hope", path: "/programs/stories/hope" },
          ],
        },

        {
          label: "Akhuwat Education Services",
          path: "/programs/aes", // New top-level path
          items: [
            { label: "College Kasur", path: "/college/kasur" },
            { label: "Akhuwat Media", path: "/media" },
            { label: "Story", path: "/story" },
            { label: "Book", path: "/book" },
            { label: "Akhuwat Active", path: "/programs/active" },
            { label: "Kasur Makhwat", path: "/kasur/makhwat" },
          ],
        },

        {
          label: "Akhuwat Health Services",
          path: "/programs/ahs", // New top-level path,
        // Adding content for displaying an image and button instead of links
        content: {
          heading: "Ramon Magsaysay Award",
          detail: "Pakistan gets Ramon Magsaysay Award",
          image: SubMenuImg1,
          buttonText: "Read More",
          buttonLink: "/programs/ahs",
        },
      },


      {
        label: "Akhuwat Clothes Bank",
        path: '/programs/acb',
        // Adding content for displaying an image and button instead of links
        content: {
          heading: "Clothes Bank",
          detail: "Pakistan gets Ramon Magsaysay Award",
          image: SubMenuImg1,
          buttonText: "Read More",
          buttonLink: "/programs/acb",
        },
      },

      {
        label: "Akhuwat Khawjasira Program",
        path: '/programs/aksp',
        // Adding content for displaying an image and button instead of links
        content: {
          heading: "Ramon Magsaysay Award",
          detail: "Pakistan gets Ramon Magsaysay Award",
          image: SubMenuImg1,
          buttonText: "Read More",
          buttonLink: "/programs/aksp",
        },
      },


      {
        label: "Akhuwat Community Development Program",
        path: "/programs/community",
        // Adding content for displaying an image and button instead of links
        content: {
          heading: "Ramon Magsaysay Award",
          detail: "Pakistan gets Ramon Magsaysay Award",
          image: SubMenuImg1,
          buttonText: "Read More",
          buttonLink: "/programs/community",
        },
      },


      {
        label: "Akhuwat Relief & Disaster Management Program",
        path: '/programs/disaster',
        // Adding content for displaying an image and button instead of links
        content: {
          heading: "Ramon Magsaysay Award",
          detail: "Pakistan gets Ramon Magsaysay Award",
          image: SubMenuImg1,
          buttonText: "Read More",
          buttonLink: "/programs/disaster",
        },
      },


        
        // Additional entries...
      ],
      image: "https://via.placeholder.com/150",
    },
    about: {
      title: "About Us",
      desc: "Learn more about our journey, mission, and team.",
      links: [
  

        {
          label: "Our Work",
          path: '/',
          items: [
            { label: "Our Philosophy", path: "/philosophy" },
            { label: "Our Work", path: "/work" },
            { label: "Get Involved", path: "/involved" },
            { label: "Contact Us", path: "/contact" },
            
          ],
        },

        {
          label: "About Us",
          path: "/about", // New top-level path
          items: [
            { label: "Our Founder", path: "/founder" },
            { label: "Our Leadership", path: "/leader" },
            { label: "Join Us", path: "/join" },
            { label: "Life at Akhuwat", path: "/life" },
            { label: "Privacy Policy", path: "/policy" },
            { label: "Our Priority", path: "/priority" },
          ],
        },
        // Additional entries...
      ],
      image: "https://via.placeholder.com/150",
    },
  };
  


  // Track which menu item is open for mobile
  const [openItem, setOpenItem] = useState(null);

  const handleMenuClick = (menuKey) => {
    setActiveMenu(activeMenu === menuKey ? null : menuKey);
    setClickedMenuItem(menuKey);
  };

  const handleToggle = (index,menuKey) => {
    // Toggle submenu for the selected menu item
    setOpenItem(openItem === index ? null : index);
    setClickedMenuItem(menuKey); //for mobile menu active color
    //alert(menuKey)
  };


  // Sample submenu items for mobile
  const menuItems = [
    { title: "Home", link: "/", subItems: [] },
    {
      title: "About", subItems: [
        { title: "About Us", link: "/about" },
        { title: "Our Founder", link: "/founder" },
        { title: "Our Leaderships", link: "/leader" },
        { title: "Our Community", link: "/programs/community" },
        { title: "Join Us", link: "/join" },
        { title: "Life At Akhuwat", link: "/life" },
        { title: "Our Work", link: "/work" },
        { title: "Our Philosophy", link: "/philosophy" },
        { title: "Disaster Management", link: "/programs/disaster" },



      ]
    },

    {
      title: "Programs", subItems: [
        { title: "Parwaz Program", link: "/program" },
        { title: "Loan Products", link: "/programs/loan/products" },
        { title: "Loan Process", link: "/programs/loan/process" },
        { title: "Our Stories of Hope", link: "/programs/stories/hope" },
        { title: "Akhuwat Islamic Microfinance", link: "/aim" },
        { title: "Akhuwat Khawajasira program", link: "/programs/aksp" },
        { title: "Akhuwat Health Services", link: "/programs/ahs" },
        { title: "Akhuwat Clothese Bank", link: "/programs/acb" },
        { title: "Akhuwat Education Services", link: "/aes" },
        { title: "Akhuwat Active", link: "/programs/active" },
      ]
    },
    {
      title: "Media", subItems: [
        { title: "Akhuwat Media", link: "/media" },
        { title: "Story", link: "/story" },
        { title: "Book", link: "/book" },
      ]
    },

    {
      title: "Get Involved", subItems: [
        { title: "College Kasur", link: "/college/kasur" },
        { title: "involved", link: "/involved" },
        { title: "Our Policy", link: "/policy" },
        { title: "Our Priority", link: "/priority" },
        { title: "Contact Us", link: "/contact" },
      ]
    },

    { title: "Login", link: "/login", subItems: [] },
  ];


  const drawer = (
    <Box sx={{
      width: { xs: '100vw', sm: '100vw', md: 250 }, // Full width on mobile and tablet, 250px on desktop
      bgcolor: 'background.paper',
    }} >
      <List>
        <ListItem button onClick={toggleDrawer(false)}>
          <CloseIcon style={{ marginRight: '50px', color: '#0F6742', width: '24.38px' }} />
        </ListItem>

        {menuItems.map((item, index) => (
          <Box key={index}>
            {/* Main menu item */}
            <ListItem
              button
              onClick={() => handleToggle(index,item.title)}
              {...(item.subItems.length === 0 && item.link ? { component: Link, to: item.link } : {})}
            >
              <ListItemText button onClick={toggleDrawer(false)}
                primary={item.title}       primaryTypographyProps={{
                  className:`${item.title === clickedMenuItem ? classes.selectedMenu : classes.menuItem}`,
                }} />
              {item.subItems.length > 0 && (
                openItem === index ? <ExpandLessIcon /> : <ExpandMoreIcon />
              )}
            </ListItem>

            {/* Submenu items */}
            {openItem === index && item.subItems.length > 0 && (
              <Box className="px-4 py-4 bg-SoftVanila rounded-[20px]"  >
                {item.subItems.map((subItem, subIndex) => (
                  <ListItem button key={subIndex} component={Link} to={subItem.link}
                  onClick={() => handleToggle(subIndex,subItem.title)}>
                    <ListItemText button onClick={toggleDrawer(false)}
                      primary={subItem.title}         primaryTypographyProps={{
                        className: `${
                          location.pathname === subItem.link ? classes.selectedMenu : classes.menuItem
                        }`,
                      }} />
                  </ListItem>
                ))}
              </Box>
            )}
          </Box>
        ))}

        <ListItem button component={Link} to="/search">
          <SearchIcon />
        </ListItem>
      </List>
    </Box>
  );






  return (
    <Box className="max-w-screen-2xl mx-auto">
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link to="/"><img src={pic} alt="pic" className={classes.logo} /></Link>
          {isMobile ? (
            <Box className={classes.mobileMenuContainer}>
              <Link to="/donate" className={classes.buttonPrice}>Donate</Link>
              <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(!drawerOpen)}>
                <MenuIcon style={{ color: '#0F6742', width: '24.38px' }} />
              </IconButton>
              <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                {drawer}
              </Drawer>
            </Box>
          ) : (
            <Box className={classes.menuContainer}>
              <Link onClick={() => handleMenuColor('Home')}
                to="/" className={`${clickedMenuItem === 'Home' ? 'text-topGreen' : 'text-SlateGray'}
             font-semibold font-manrope text-[16px] text-left leading-[24px]
             cursor-pointer mr-8 hover:text-topGreen`}
              >Home</Link>
              <li onClick={() => handleMenuClick('about')} className={`${clickedMenuItem === 'about' ? 'text-topGreen' : 'text-SlateGray'}
             font-semibold font-manrope text-[16px] text-left leading-[24px]
             cursor-pointer mr-8 hover:text-topGreen list-none`}
              >About</li>
              <li onClick={() => handleMenuClick('programs')} className={`${clickedMenuItem === 'programs' ? 'text-topGreen' : 'text-SlateGray'}
             font-semibold font-manrope text-[16px] text-left leading-[24px]
             cursor-pointer mr-8 hover:text-topGreen list-none`}
              >Programs</li>

              <Link to="/createBlog" onClick={() => handleMenuColor('blogs')}
                className={`${clickedMenuItem === 'blogs' ? 'text-topGreen' : 'text-SlateGray'}
             font-semibold font-manrope text-[16px] text-left leading-[24px]
             cursor-pointer mr-8 hover:text-topGreen list-none`}
              >Blogs</Link>

              <Link to="/login" onClick={() => handleMenuColor('login')}
                className={`${clickedMenuItem === 'login' ? 'text-topGreen' : 'text-SlateGray'}
             font-semibold font-manrope text-[16px] text-left leading-[24px]
             cursor-pointer mr-8 hover:text-topGreen list-none`}
              >Login</Link>


              <Link to="/" className={classes.menuItem}><SearchIcon /></Link>
              <Link to="/donate" className={classes.buttonPrice}>Donate</Link>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Render Submenu based on active menu item */}
      <Submenu
        title={submenuData[activeMenu]?.title}
        links={submenuData[activeMenu]?.links || []}
        image={submenuData[activeMenu]?.image}
        isVisible={!!activeMenu}
        desc={submenuData[activeMenu]?.desc}
      />
    </Box>
  );
}

export default Nav;
