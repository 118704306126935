import { Box } from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography";
import ProductDesign from "./ProductDesign";
import familyImg from "../pictures/loan_family.png"
import LiberImg from "../pictures/product-liberation.png"
import AgriImg from "../pictures/product-agri.png"
import HealthImg from "../pictures/product-health.png"
import EduImg from "../pictures/product-edu.png"
import HouseImg from "../pictures/product-house.png"
import MarriageImg from "../pictures/product-marriage.png"
import EmergencyImg from "../pictures/product-emergency.png"
import CustomButton from "../ReusableComponents/CustomButton";
const LoanProducts = () => {
    return (

        <Box>

            <Box className="lg:py-16 lg:px-16 md:py-18 md:px-16 py-6 px-[18px]  bg-primaryLight">


                <Box className="lg:py-120px lg:px-16 py-8 px-6  bg-white rounded-[30px] max-w-screen-2xl mx-auto">

                    <CustomTypography as="h2" variant="h21" color="text-customBlack" alignment="text-center"
                        weightFont="font-bold">
                        Loan Products
                    </CustomTypography>


                    <CustomTypography className="lg:pt-10 md:p-10 pt-6" as="p" variant="p2" color="text-SlateGray" alignment="text-center"
                        weightFont="font-normal">
                        Akhuwat Islamic Microfinance (AIM) provides interest-free loans to enable people
                        and help them earn their living, grow their small businesses, and educate their
                        children. AIM also supports farmers, empowers women and transgenders, facilitates
                        building affordable houses, and contributes to overall social development.
                    </CustomTypography>

                </Box>

            </Box>


            {/* Products */}
             <Box className="max-w-screen-2xl mx-auto">
            <ProductDesign gridImg={familyImg} productTitle="Family Enterprise Loan" />
            <ProductDesign gridImg={AgriImg} productTitle="Agriculture Loan" />
            <ProductDesign gridImg={HealthImg} productTitle="Health Loan" />
            <ProductDesign gridImg={EduImg} productTitle="Education Loan" />
            <ProductDesign gridImg={HouseImg} productTitle="Housing Loan" />
            <ProductDesign gridImg={MarriageImg} productTitle="Marriage Loan" />
            <ProductDesign gridImg={EmergencyImg} productTitle="Emergency Loan" />
            <ProductDesign gridImg={LiberImg} productTitle="Liberation Loan" />
            </Box>


            {/* Branch section */}
            
            <Box className=" bg-MintCream">
            <Box className="max-w-screen-2xl mx-auto grid lg:grid-cols-2 grid-cols-1 lg:py-120px lg:px-120px py-6 px-4
            md:py-60px md:px-10 lg:gap-10 gap-4">

                <CustomTypography as="h2" variant="h2" color="text-customGreen" alignment="lg:text-left text-center"
                    weightFont="font-bold">
                    Visit Your Nearest AIM Branch
                </CustomTypography>


                <Box>

                    <CustomTypography as="p" variant="p3" color="text-SlateGray" alignment="lg:text-left text-center"
                        weightFont="font-normal">
                        Whether you're looking to apply for a loan or learn more about what we offer, we're here to assist
                        you every step of the way. Visit your nearest branch for personalized support, or easily locate
                        one by clicking the link below
                    </CustomTypography>


                    <Box className="flex lg:justify-start justify-center lg:pt-10 md:pt-10 pt-6">

                        <CustomButton btnURL="/network" btnText="Branch network" btnColor="white"
                            btnBackground="customBlack" />

                    </Box>

                </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default LoanProducts;