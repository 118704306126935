import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CustomTypography from './CustomTypography';
const MAX_CONTAINER_WIDTH = 1536; // Tailwind's max-w-screen-2xl

const CustomCarousel = ({
    slides,
    slideWidth = { lg: 478, md: 400, sm: 310 },
    gap = 40,
    autoplay = true,
    interval = 5000,
    sliderTitle,
    ArrowPosition = "top",
    ArrowInActiveColor = "#A6A6A6",
    ArrowActiveColor = "#2A2A2A",
    ArrowAligned = "end",
    SliderTitleColor = 'text-topGreen',
    showLineNav = "no"
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slidesPerPage, setSlidesPerPage] = useState(1);
    const [currentSlideWidth, setCurrentSlideWidth] = useState(slideWidth.lg);
    const [containerWidth, setContainerWidth] = useState(window.innerWidth);

    const updateSlidesPerPage = () => {
        const totalWidth = Math.min(window.innerWidth, MAX_CONTAINER_WIDTH);
        let appliedSlideWidth = slideWidth.lg;

        if (totalWidth < 768) {
            appliedSlideWidth = totalWidth;
        } else if (totalWidth <= 1024) {
            appliedSlideWidth = slideWidth.md;
        }

        const totalSpacePerSlide = appliedSlideWidth + gap;
        const slidesToShow = Math.floor((totalWidth + gap) / totalSpacePerSlide);

        setSlidesPerPage(slidesToShow || 1);
        setCurrentSlideWidth(appliedSlideWidth);
        setContainerWidth(totalWidth);
    };


    useEffect(() => {
        updateSlidesPerPage();
        window.addEventListener('resize', updateSlidesPerPage);
        return () => window.removeEventListener('resize', updateSlidesPerPage);
    }, [slideWidth, gap]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + slidesPerPage >= slides.length ? slides.length - slidesPerPage : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
    };

    useEffect(() => {
        let autoplayInterval;
        if (autoplay) {
            autoplayInterval = setInterval(() => {
                nextSlide();
            }, interval);
        }
        return () => {
            if (autoplayInterval) clearInterval(autoplayInterval);
        };
    }, [autoplay, interval, currentIndex]);

    const calculateTranslateX = () => {
        const offset = (currentSlideWidth + gap) * currentIndex;
        const maxOffset = (slides.length - slidesPerPage) * (currentSlideWidth + gap);

        // Ensure the offset does not go beyond the total slides width
        return Math.max(-offset, -maxOffset);
    };

    const handleChangeSlideTestim = (index) => {
        setCurrentIndex(index);
    };

    return (
        <Box
            className={`carousel-container overflow-hidden  `}
            style={{
                width: '100%',
                maxWidth: `${MAX_CONTAINER_WIDTH}px`,
                margin: '0 auto',

            }}
        >
            <Box className='grid lg:grid-cols-2 grid-cols-1'>
                <Box className='flex lg:justify-start justify-center items-center pl-16'>
                    <CustomTypography as="h2" variant="h2" color={SliderTitleColor} weightFont="font-bold"
                        alignment='lg:text-left text-center'
                    >
                        {sliderTitle}
                    </CustomTypography>
                </Box>

                {ArrowPosition === "top" && (
                    <Box className={`col-span-1 px-16 lg:flex hidden items-center justify-${ArrowAligned}`}>
                        <Box
                            onClick={prevSlide}
                            className={`cursor-pointer ${currentIndex === 0 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-white flex items-center justify-center`}
                            style={{ borderColor: currentIndex === 0 ? ArrowInActiveColor : ArrowActiveColor }}
                        >
                            <KeyboardArrowLeftIcon style={{ color: currentIndex === 0 ? ArrowInActiveColor : ArrowActiveColor }} />
                        </Box>
                        <Box
                            onClick={nextSlide}
                            className={`ml-4 cursor-pointer ${currentIndex >= slides.length - slidesPerPage ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-[#2A2A2A] flex items-center justify-center`}
                            style={{ borderColor: ArrowActiveColor }}
                        >
                            <KeyboardArrowRightIcon style={{ color: ArrowActiveColor }} />
                        </Box>
                    </Box>
                )}
            </Box>

            <Box className={`lg:flex md:flex hidden slider-wrapper pt-60px lg:pl-16 md:pl-10
                ${currentIndex >= slides.length - slidesPerPage ? 'pr-16' : 'pr-0'}`} style={{ transition: 'transform 0.5s ease', transform: `translateX(${calculateTranslateX()}px)` }}>
                {slides.map((slide, index) => (
                    <Box
                        key={index}
                        className="slide"
                        style={{
                            minWidth: `${currentSlideWidth}px`,
                            marginRight: `${gap}px`,
                            boxSizing: 'border-box',
                        }}
                    >
                        {slide}
                    </Box>
                ))}
            </Box>

            <Box className="pt-8 lg:hidden md:hidden">
                <Carousel
                    selectedItem={currentIndex}
                    onChange={(index) => setCurrentIndex(index)}
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                    interval={2000}
                    showIndicators={false}
                    autoPlay infiniteLoop
                >
                    {slides.map((slide, index) => (
                        <div
                            key={index}
                            className='ml-2 mr-2 '
                        >
                            {slide}
                        </div>
                    ))}
                </Carousel>
            </Box>

            {ArrowPosition !== "top" && (
                <Box className={`col-span-1 lg:flex hidden items-center px-16 pt-60px justify-${ArrowAligned}`}>
                    <Box
                        onClick={prevSlide}
                        className={`cursor-pointer ${currentIndex === 0 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-white flex items-center justify-center`}
                        style={{ borderColor: currentIndex === 0 ? ArrowInActiveColor : ArrowActiveColor }}
                    >
                        <KeyboardArrowLeftIcon style={{ color: currentIndex === 0 ? ArrowInActiveColor : ArrowActiveColor }} />
                    </Box>
                    <Box
                        onClick={nextSlide}
                        className={`ml-4 cursor-pointer ${currentIndex >= slides.length - slidesPerPage ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-[#2A2A2A] flex items-center justify-center`}
                        style={{ borderColor: ArrowActiveColor }}
                    >
                        <KeyboardArrowRightIcon style={{ color: ArrowActiveColor }} />
                    </Box>
                </Box>
            )}

            {sliderTitle !== 'Testimonials' && (
                <Box className={`flex justify-center items-center pt-[52px] ${showLineNav === 'yes' ? 'lg:flex md:flex' : 'lg:hidden md:hidden'}`}>
                    <div className="flex justify-center items-center">
                        {[...Array(slides.length).keys()].map((index) => (
                            <button
                                key={index}
                                onClick={() => handleChangeSlideTestim(index)}
                                className={`w-[48px] h-[4px] rounded-full focus:outline-none ml-2 ${currentIndex === index ? 'bg-topGreen' : 'bg-topAlmond'}`}
                            />
                        ))}
                    </div>
                </Box>
            )}


            {sliderTitle === 'Testimonials' && (
                <Box className='flex justify-center pt-[52px]'>
                    {[...Array(slides.length).keys()].map((index) => (
                        <button
                            key={index}
                            onClick={() => handleChangeSlideTestim(index)}
                            className={`w-[48px] h-[4px] rounded-full focus:outline-none ml-2 ${currentIndex === index ? 'bg-topGreen' : 'bg-topAlmond'}`}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default CustomCarousel;
