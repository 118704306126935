import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box } from "@material-ui/core";
import EastIcon from '@mui/icons-material/East';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import CustomTypography from './CustomTypography';
import { Link } from 'react-router-dom';




const useStyles = makeStyles((theme) => ({

    cardDesc: {

        fontFamily: 'Manrope!important',
        fontSize: '24px!important',
        lineHeight: '32.78px!important',
        fontWeight: '600!important',
        letterSpacing: '0.22px',
        color: '#329A51!important',
        width: '450px!important',
        textAlign: 'left!important',
        [theme.breakpoints.down('sm')]: {
            fontWeight: '700!important',
            width: '250px!important',
            color: '#2A2A2A!important',
        },
    },

    cardStyle: {

        height: 'auto',
        width: '580px',

        [theme.breakpoints.down('sm')]: {
           
            width: '100%',
        },
    },

    cardMediaStyle: {

        height: '275px',

        [theme.breakpoints.down('sm')]: {
            height: '245px'

        },
    },

}));

const ProgramsCards = ({ programHead, programDesc, programImg,cardLink }) => {
    const classes = useStyles();

    return (

        <Card className={classes.cardStyle} style={{ borderRadius: '20px' }}>
            <CardMedia className={classes.cardMediaStyle}
                image={programImg}
                title="green iguana"
            />
<Link to={cardLink} >
                <CardContent style={{ padding: '24px' }}>
                    <CustomTypography as="p" variant="p2" color="text-topGreen" weightFont="font-semibold">
                        {programHead}
                    </CustomTypography>
                    <Box className='pt-2'>
                        <Box className="flex justify-between items-center">
                            <CustomTypography as="p" variant="p3" color="text-topGray" weightFont="font-normal">
                                {programDesc}
                            </CustomTypography>
                        </Box>
                    </Box>
                </CardContent>
            </Link>
        </Card>
    )
}


export default ProgramsCards