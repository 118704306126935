import { Box } from '@material-ui/core';
import React from 'react';
import CustomTypography from './CustomTypography';

const MediaCard = ({ cardImg, cardTitle, titleColor, titleBackColor, cardDesc, cardDate }) => {
    return (
        <Box className="rounded-[20px] overflow-hidden">
            <img src={cardImg} alt="card image"  className='object-cover w-full'/>

            <Box className="bg-white p-5">
                {/* Wrap the title box in a flex container to ensure left alignment */}
                <Box className="flex justify-start ">
                    <Box className={`py-1 px-4 bg-${titleBackColor} inline-block rounded-full`}>
                        <h1 className={`font-manrope font-bold text-${titleColor} text-[12.44px] leading-[14.93px]`}>
                            {cardTitle}
                        </h1>
                    </Box>
                </Box>

                <CustomTypography className="pt-3 text-left" as="p" variant="p2" weightFont="font-semibold" color="customBlack">
                    {cardDesc}
                </CustomTypography>

                <Box className="pt-5 text-left">
                    <p className="font-manrope font-normal text-topGray text-[13.23px] leading-[18px]">
                        {cardDate}
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

export default MediaCard;
