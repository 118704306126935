import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import eman from '../pictures/eman.png'
import ehsan from '../pictures/ehsan.png'
import ikhlas from '../pictures/ikhlas.png'
import Infaq from '../pictures/Infaq.png'
import FlipCard from './FlipCard';
import CustomCaraousel from '../CustomCarasouel'
import CustomTypography from '../CustomTypography';
import PrincipalTestimonials from './PrincipalTestimonials';

const OurPrinciple = () => {



    const slides = [
        <FlipCard cardNumber='01' frontImage={eman} backHead='Eman'
            backPara='Iman or faith, is the human trust or belief in a transcendent reality that inspires a sense of responsibility and duty. The rewards of fulfilling such a duty may not be material and/ or rewarded in this world. Belief in Allah and the Day of Judgment is central to Iman as it inspires one to be cognizant that they will be accountable for their worldly deeds.'
        />,
        <FlipCard cardNumber={'02'} frontImage={ehsan} backHead={'Ehsan'}
            backPara={'The literal meaning of Ihsan is ‘to do beautiful things’ such that in every act undertaken, one is cognizant of Allah’s presence. Under the Mawakhat paradigm, Ihsan entails that every individual strives for excellence in character, work, service, and knowledge. '} />,

        <FlipCard cardNumber={'03'} frontImage={ikhlas} backHead={'Ikhlas'}
            backPara={'Ikhlas, or purity, refers to the sincerity of intention and action. To purge one’s intentions of all impurities requires self-discipline, submission of the ego, and a persistent struggle. Under Mawakhat, Ikhlas requires one to pursue goodness for the sake of goodness alone, and thus to be free from pretence, deceit, or hypocrisy.'} />,
        <FlipCard cardNumber={'04'} frontImage={Infaq} backHead={'Infaq'}
            backPara={'Infaq refers to giving in the way of Allah and can be loosely translated into spending giving. without reward from anyone but Allah. As a means of equitable distribution of income and other resources, Infaq remains the same for the poor and rich; one must give according to his or her ability to those who have comparatively fewer resources. '} />
    ]



    const PrincipleCards = [

        <PrincipalTestimonials PrincipleTitle="Interest free microloans"
            PrincipleDesc="To alleviate poverty by empowering socially and economically
        marginalized segments of the society through interest-free microfinance and education" />,

        <PrincipalTestimonials PrincipleTitle=" Religious Places"
            PrincipleDesc="Akhuwat uses religious places including mosques, churches, and temples
           as places for loan disbursement ceremonies. Religious places ensure transparency,
        participation, and accountability while also minimizing operational costs and generating goodwill." />,


        <PrincipalTestimonials PrincipleTitle="Volunteerism"
            PrincipleDesc="Akhuwat relies upon the passion, dedication and zeal of its volunteer
                            team who dedicate their time, talenPolicy of Non-Discriminationt, and
                            resources to the mission of Akhuwat." />,



        <PrincipalTestimonials PrincipleTitle="Policy of Non-Discrimination"
            PrincipleDesc="Akhuwat believes in an inclusive society without any discrimination on
                            the basis of caste, color, creed, gender, politics or faith. Akhuwat
                            is a nonpolitical and pro-poor organization" />,


        <PrincipalTestimonials PrincipleTitle=" Cycle of Generosity"
            PrincipleDesc="Akhuwat strives to empower its borrowers, so that they can eventually become donors
                            to the cause and contribute to the mission of establishing a poverty-free society.
                            Akhuwat puts this reciprocity of the economy into action, so that it can inspire a
                            cycle of generosity for the goodwill of everyone who is underprivileged and can’t meet their needs." />,

    ]


    return (
        <Box className='bg-MistyLilc'>
            {/**Our principle  */}

            <Box className='max-w-screen-2xl mx-auto h-auto  lg:py-120px py-8  lg:px-120px md:px-9 px-4'>
                <Box className='lg:grid grid-cols-3  gap-5 hidden'>
                    <Box className='min-h-[428px] p-6'>
                        <CustomTypography as="h2" variant="h2" color="text-customBlack "
                        >
                            Our Principles
                        </CustomTypography>
                        <CustomTypography className='pt-8' as="p" variant="p3" color="text-SlateGray " weightFont="font-normal"
                        >
                            Akhuwat evolved as an organic entity through a process of action research. The
                            following major principles were adopted with time.
                        </CustomTypography>
                    </Box>
                    {PrincipleCards}
                </Box>
            </Box>

            {/** For mobile view prinicple section */}


            <Box className="lg:hidden  relative py-8 md:pl-9 md:pr-0 pl-4 pr-4">
                <Box className='pb-6'>
                    <CustomTypography as="h2" variant="h2" color="text-customBlack "
                        alignment='text-center'
                    >
                        Our Principles
                    </CustomTypography>


                    <CustomTypography className='pt-6' as="p" variant="p3" color="text-SlateGray " weightFont="font-normal" alignment='text-center'
                    >
                        Akhuwat evolved as an organic entity through a process of action research. The
                        following major principles were adopted with time.
                    </CustomTypography>
                </Box>

                {/* Carasoule for mobile and tablet */}
                <Box>

                    <CustomCaraousel slides={PrincipleCards} slideWidth={{
                        lg: 580,
                        md: 400,
                        sm: 288
                    }} gap={25}
                        ArrowPosition='bottom'
                        ArrowActiveColor='customBlack'
                        ArrowInActiveColor='#A6A6A6'

                    />

                </Box>

            </Box>


            {/**Our Values section */}

            <Box className=' h-auto lg:py-120px py-8  lg:px-120px md:px-9 px-4 bg-white '>
                <Box className='max-w-screen-2xl mx-auto '>
                    <Box className='text-center items-center'>

                        <CustomTypography as="h2" variant="h2" color="text-customBlack " alignment='text-center'
                            weightFont="font-bold"
                        >
                            Our Values
                        </CustomTypography>

                        <CustomTypography className='pt-10' as="p" variant="p2" color="text-customBlack " alignment='text-center'
                            weightFont="font-normal"
                        >
                            Akhuwat recognizes four core values that support the spirit of Mawakhat:
                            Iman, Ihsan, Ikhlas, and Infaq—faith, excellence, sincerity, and giving.
                            Together, these values merge into the spirit of brotherhood and solidarity.
                        </CustomTypography>

                    </Box>


                    <Box className="hidden lg:grid grid-cols-2  gap-5 pt-[90px] ">
                        {slides}
                    </Box>
                </Box>



            </Box>

            {/** mobile and tablet carasoule */}
            <Box className='lg:hidden md:pl-9 pl-4 md:pr-0 pr-0 pb-8'>

                <CustomCaraousel slides={slides} slideWidth={{
                    lg: 580,
                    md: 321,
                    sm: 288
                }} gap={16}
                    ArrowPosition='bottom'
                    ArrowActiveColor='white'
                    ArrowInActiveColor='white'

                />

            </Box>

        </Box>
    )
}

export default OurPrinciple