import React,{useState} from "react";
import { Box } from "@material-ui/core";
import heroVideo from './pictures/hero_video.mp4';
import CountUp from 'react-countup';
import LatestNews from "./LatestNews";
import Faq from "./Faq";
import Programs from "./Programs";
import HeroDonate from "./HeroDonate";
import { useMediaQuery, useTheme } from '@material-ui/core';
import NumberPattern from "./pictures/home-number-pattern.png"
import CustomTypography from "./CustomTypography";
import CustomButton from "./ReusableComponents/CustomButton";
const Home = () => {


  const [activeBtn, setActiveBtn] = useState("Rs. 1000");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  return (
    <Box >
      <Box className="h-auto pt-3 lg:px-16 md:px-10 px-4 lg:pb-60px pb-6">
        <Box className="lg:hidden hero-content-mobile mb-4">


          <CustomTypography as="h1" variant="h1" color="text-customBlack" weightFont="font-semibold"
          alignment="text-center">
            Envisioning a Poverty-free Society
          </CustomTypography>
        </Box>
        <Box className="lg:h-[682px] md:h-[301px] w-full h-[163px] relative lg:rounded-[30px] rounded-[15px] lg:py-[60px] py-[0px]  overflow-hidden
        max-w-screen-2xl mx-auto">
          <video autoPlay loop muted className=" absolute top-0 left-0 w-full h-full object-cover z-0">
            <source src={heroVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Box className="z-1 absolute bottom-0 left-0 w-full lg:grid grid-cols-2 hidden p-60px gap-10">
            <Box className=" flex items-end">
              <CustomTypography as="h1" variant="h1" color="text-white" weightFont="font-semibold">
                Envisioning a Poverty-free Society
              </CustomTypography>
            </Box>

            <Box className=" flex items-end justify-end">
              <HeroDonate />
            </Box>
          </Box>

        </Box >

        <Box className="lg:hidden ">
          <HeroDonate />
        </Box>
      </Box>

      {/* Impact number section 2nd */}

      <Box className=" h-auto lg:pt-[90px] pt-8 lg:px-120px px-6 lg:pb-[140px] pb-8 bg-LightBrown
      "
        style={{
          backgroundImage: !isMobile ? `url(${NumberPattern})` : 'none',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right top'
        }}>
          <Box className='max-w-screen-2xl mx-auto'>

         
        <Box >


          <CustomTypography as="h2" variant="h2" color="text-customGreen" weightFont="font-semibold">
            Akhuwat’s Impact in Numbers
          </CustomTypography>
        </Box>
        <Box className="grid lg:grid-cols-3 grid-cols-1 lg:gap-2 gap-[70px] lg:pt-[130px] pt-12">

          {/* 1st Column: Left Aligned */}
          <Box className="flex flex-col items-start">
            <Box className="flex items-end">
              <h1>
                <CountUp className="font-bebs text-customGreen lg:text-[120px] text-[80px] lg:leading-[120px] leading-[80px] font-normal mb-[15px]" end={205.55} decimals={2} suffix="B" />
                <span className="font-manrope text-customGreen lg:text-[30px] text-[20px] leading-[30px] font-bold absolute lg:pt-2 pt-[4px] pl-2">PKR</span>
              </h1>
            </Box>
            <CustomTypography as="p" variant="p3" color="text-customGreen" weightFont="font-semibold">
              DISBURSED IN INTEREST-FREE LOANS
            </CustomTypography>
          </Box>

          {/* 2nd Column: Center Aligned */}
          <Box className=" lg:justify-self-end">
            <Box >
              <h1>
                <CountUp className="font-bebs text-customGreen lg:text-[120px] text-[80px] lg:leading-[120px] leading-[80px] font-normal mb-[12px] " end={4} suffix="M+" />
              </h1>
            </Box>
            <Box className="interest-text2">


              <CustomTypography as="p" variant="p3" color="text-customGreen" weightFont="font-semibold">
              FAMILIES EMPOWERED
              </CustomTypography>
            </Box>
          </Box>

          {/* 3rd Column: Right Aligned */}
          <Box className=" lg:justify-self-end">
            <Box>
              <h1>
                <CountUp className="font-bebs text-customGreen lg:text-[120px] text-[80px] lg:leading-[120px] leading-[80px] font-normal mb-[12px] tracking-[-0.03em]" end={99.9} decimals={1} suffix="%" />
              </h1>
            </Box>
            <Box>


              <CustomTypography as="p" variant="p3" color="text-customGreen" weightFont="font-semibold">
                RECOVERY RATE
              </CustomTypography>
            </Box>
            </Box  >
          </Box>


        </Box>


      </Box>


      <Programs />


      <LatestNews />
      <Faq />


      {/* Donate section */}


      <Box className="bg-customGreen lg:py-140px lg:px-16 md:py-60px md:px-10 py-6 px-4">

        <Box className="bg-white lg:py-60px lg:px-[100px] rounded-[16px] md:py-10 md:px-10 py-5 px-5
         max-w-screen-2xl mx-auto">

          <CustomTypography
            as='h2' variant='h2' color='text-customBlack' weightFont='font-bold' alignment='text-center'
          >
           Turn Your Empathy into Action Header
          </CustomTypography>



          <CustomTypography className="pt-5"

            as='p'
            variant='p2'
            color="text-SlateGray"
            weightFont='font-semibold'
            alignment="text-center"

          >
           Your generosity holds the power to change lives. Support us in alleviating poverty and 
           empowering those who deserve help.
          </CustomTypography>



          <Box className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1
    lg:gap-5 md:gap-5 gap-3 lg:pt-10 md:pt-10 pt-6">

            <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
         ${activeBtn === "Rs. 1000" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
              onClick={() => setActiveBtn("Rs. 1000")}>
              <CustomTypography
                as='p' variant='p3' color={` ${activeBtn === "Rs. 1000" ? "text-white" : "text-customBlack"}`}
                weightFont='font-normal' alignment='text-center'
              >
                Rs. 1000
              </CustomTypography>
            </Box>



            <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
         ${activeBtn === "Rs. 2500" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
              onClick={() => setActiveBtn("Rs. 2500")}>
              <CustomTypography
                as='p' variant='p3' color={` ${activeBtn === "Rs. 2500" ? "text-white" : "text-customBlack"}`}
                weightFont='font-normal' alignment='text-center'
              >
                Rs. 2500
              </CustomTypography>
            </Box>

            <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
         ${activeBtn === "Rs. 5000" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
              onClick={() => setActiveBtn("Rs. 5000")}>
              <CustomTypography
                as='p' variant='p3' color={` ${activeBtn === "Rs. 5000" ? "text-white" : "text-customBlack"}`}
                weightFont='font-normal' alignment='text-center'
              >
                Rs. 5000
              </CustomTypography>
            </Box>



            <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
         ${activeBtn === "Other amount" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
              onClick={() => setActiveBtn("Other amount")}>
              <CustomTypography
                as='p' variant='p3' color={` ${activeBtn === "Other amount" ? "text-white" : "text-customBlack"}`}
                weightFont='font-normal' alignment='text-center'
              >
                Other amount
              </CustomTypography>
            </Box>






          </Box>

          <Box className="flex justify-center lg:pt-10 md:pt-10 pt-6">
            <CustomButton btnText="Donate" btnColor="white" btnBackground="customBlack" />

          </Box>

        </Box>

      </Box>

    </Box>
  );
};

export default Home;
