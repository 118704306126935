import React from 'react';
import { Box } from '@material-ui/core';
import CustomButton from '../ReusableComponents/CustomButton';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CustomTypography from '../CustomTypography';

const AkCollege = ({ colgImage, collegeHead, collegePara, backColor,
    paraColor, regionBorder, btnColor, headColor, rightpattern, leftPattern, commBox, commTopHead }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    //commBox for the cards being used on Community page

    return (
        <Box>
            {/* College cards */}
            <Box className='grid grid-cols-1 '>
                {colgImage && (
                    <Box>
                        <img className='lg:h-[675px] md:h-[316px] h-[255px] w-full rounded-t-[40px]' src={colgImage} alt="kasur" />
                    </Box>
                )}

                <Box className={`h-auto rounded-b-[40px] lg:py-60px py-6 lg:px-60px px-5 bg-${backColor}`}
                    style={{
                        border: regionBorder ? '1px solid #2A2A2A' : 'none',
                        borderTopLeftRadius: regionBorder ? '40px' : 'none',
                        borderTopRightRadius: regionBorder ? '40px' : 'none',

                        backgroundImage: !isMobile ? `url(${rightpattern}),url(${leftPattern})` : 'none',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom right,top left',

                    }}
                >
                    <Box>
                        {/* condition to check community page */}

                        {commBox === "community" ? (

                            <>
                                <h6 className='font-manrope font-extrabold text-center text-[14px] leading-[14px]
                    text-customBlack'>{commTopHead}</h6>

                           

                                    <CustomTypography className='pt-2'
                                as='h2' variant='h21' color='text-topGreen' weightFont='font-bold' alignment="text-center"
                            >
                                {collegeHead}
                            </CustomTypography>


                            </>
                        ) :



                            <CustomTypography
                                as='h2' variant='h2' color={headColor} weightFont='font-bold' alignment="text-center"
                            >
                                {collegeHead}
                            </CustomTypography>



                        }

                        <p className={`lg:pt-8 pt-5 font-manrope lg:text-center lg:font-normal font-medium lg:text-[18px]
                     text-[16px] lg:leading-[27px] leading-[24px] text-${paraColor}
                    text-center`}
                        >
                            {collegePara}
                        </p>
                        


                        <Box className={`lg:pt-8 pt-5 flex lg:justify-center items-center
                             justify-center `}>
                            <CustomButton btnText='Learn more' btnColor={btnColor} btnBackground={paraColor} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AkCollege;
