import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from 'react-helmet';
import './App.css';
import Nav from './Component/Nav';
import Home from "./Component/Home";
import React from "react";
import Footer from "./Component/Footer";
import About from "./Component/About/About";
import Aes from "./Component/AES_AIM/Aes";
import Aim from "./Component/AES_AIM/Aim";
import Aksp from "./Component/AES_AIM/Aksp";
import AkFounder from "./Component/Founder/AkFounder";
import Ahs from "./Component/ACB_AHS/Ahs";
import Acb from "./Component/ACB_AHS/Acb";
import Leadership from "./Component/Founder/Leadership";
import Community from "./Component/Founder/Community";
import Disaster from "./Component/Founder/Disaster";
import MakhwatBook from "./Component/Founder/MakhwatBook";
import PerwazProgram from "./Component/Founder/PerwazProgram";
import LoanProcess from "./Component/SubPages/LoanProcess";
import LoanProducts from "./Component/SubPages/LoanProducts";
import GetInvolved from "./Component/SubPages/GetInvolved";
import JoinUs from "./Component/SubPages/JoinUs";
import AkhuwatPriority from "./Component/SubPages/AkhuwatPriority";
import AkhuwatStory from "./Component/SubPages/AkhuwatStory";
import AkhuwatPhilosophy from "./Component/SubPages/AkhuwatPhilosophy";
import OurWork from "./Component/SubPages/OurWork";
import PrivacyPolicy from "./Component/SubPages/PrivacyPolicy";
import LifeAtAkhuwat from "./Component/SubPages/LifeAtAkhuwat";
import ContactUs from "./Component/SubPages/ContactUs";
import AkhuwatMedia from "./Component/SubPages/AkhuwatMedia";
import HopeStories from "./Component/SubPages/HopeStories";
import AkhuwatActive from "./Component/SubPages/AkhuwatActive";
import AkhuwatKasur from "./Component/BranchPages/AkhuwatKasur";
import ScrollToTop from "react-scroll-to-top";
import { FaChevronUp } from "react-icons/fa";
import BlogUpload from "./Component/AdminDashbord/blogupload";
import Dashboard from "./Component/AdminDashbord/Dashboard";
import Register from "./Component/AdminDashbord/Register";
import Login from "./Component/AdminDashbord/Login";
import { useSelector } from "react-redux";
import store from "./Component/Store";
import { loadUser } from "./Component/Actions/userAction";
import UserOptions from "./Component/UserOptions";
import Blogs from "./Component/SubPages/Blogs";
import KasurMakhwat from "./Component/BranchPages/KasurMakhwat";
import JobUpload from "./Component/AdminDashbord/jobUpload";
import OpenPositions from "./Component/BranchPages/OpenPositions";
import AllJobs from "./Component/AdminDashbord/AllJobs";
import BlogDetail from "./Component/SubPages/BlogDetail";

function App() {

  const {isAuthenticated,user}=useSelector(state=>state.user)



  React.useEffect(()=>{

   store.dispatch(loadUser())

  },[])


  return (
    <>
  <div>
            {/* Scroll to top icon */}
            <ScrollToTop
                smooth
                component={<FaChevronUp />}
                style={{
                    backgroundColor: "#333",
                    borderRadius: "50%",
                    width: "45px",
                    height: "45px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "transform 0.3s ease",
                }}
                className="scroll-to-top"
            />
            <style jsx>{`
                .scroll-to-top:hover {
                    transform: scale(1.1);
                    background-color: #555;
                }
            `}</style>
        </div>
    
    <Router>
    {isAuthenticated && <UserOptions user={user} />}
      <Nav />

      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Helmet>
                <title>Home - Akhuwat</title>
                <meta name="description" content="Poverty free Society" />
                <meta name="keywords" content="Akhuwat, vision, mission, charity" />
                <meta name="robots" content="index, follow" />
                {/* Open Graph Tags for social media sharing */}
                <meta property="og:title" content="Home - Akhuwats" />
                <meta property="og:description" content="Learn about our vision and mission at Akhuwat." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="http://localhost:3000" />
                {/* Canonical Tag */}
                <link rel="canonical" href="http://localhost:3000" />
              </Helmet>
              {isAuthenticated? <Home/> : <Login/>}
            </>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <>
              <Helmet>
                <title>About Us - Akhuwats</title>
                <meta name="description" content="Oru vision and mission" />
                <meta name="keywords" content="Akhuwat, vision, mission, charity" />
                <meta name="robots" content="index, follow" />
                {/* Open Graph Tags for social media sharing */}
                <meta property="og:title" content="About Us - Akhuwat" />
                <meta property="og:description" content="Learn about our vision and mission at Akhuwat." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="http://localhost:3000/about" />
                {/* Canonical Tag */}
                <link rel="canonical" href="http://localhost:3000/about" />
              </Helmet>
            {isAuthenticated &&<About /> }
            </>
          }
        />

        <Route
          exact
          path="/aes"
          element={
            <>
              <Helmet>
                <title>AES - Akhuwat</title>
                <meta name="description" content="Description for the AES page." />
                <meta name="keywords" content="Akhuwat, vision, mission, charity" />
                <meta name="robots" content="index, follow" />
                {/* Open Graph Tags for social media sharing */}
                <meta property="og:title" content="AES - Akhuwat" />
                <meta property="og:description" content="Learn about our vision and mission at Akhuwat." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="http://localhost:3000/aes" />
                {/* Canonical Tag */}
                <link rel="canonical" href="http://localhost:3000/aes" />
              </Helmet>
             { isAuthenticated &&<Aes />}
            </>
          }
        />
        <Route
          exact
          path="/aim"
          element={
            <>
              <Helmet>
                <title>AIM - Akhuwat</title>
                <meta name="description" content="Akhuwat Microfinance" />
                <meta name="keywords" content="Akhuwat, vision, mission, charity" />
                <meta name="robots" content="index, follow" />
              </Helmet>
             {isAuthenticated && <Aim /> }
            </>
          }
        />
        <Route
          exact
          path="/founder"
          element={
            <>
              <Helmet>
                <title>Founder - Akhuwat</title>
                <meta name="description" content="Our Founder message" />
              </Helmet>
             {isAuthenticated && <AkFounder />}
            </>
          }
        />
        <Route
          exact
          path="/programs/aksp"
          element={
            <>
              <Helmet>
                <title>AKSP - Akhuwat</title>
                <meta name="description" content="Akhuwat programs" />
              </Helmet>
              {isAuthenticated &&<Aksp />}
            </>
          }
        />
        <Route
          exact
          path="/programs/ahs"
          element={
            <>
              <Helmet>
                <title>AHS - Akhuwat</title>
                <meta name="description" content="Akhuwat healh services" />
              </Helmet>
              {isAuthenticated &&<Ahs />}
            </>
          }
        />
        <Route
          exact
          path="/programs/acb"
          element={
            <>
              <Helmet>
                <title>ACB - Akhuwat</title>
                <meta name="description" content="Clothese " />
              </Helmet>
              {isAuthenticated &&<Acb />}
            </>
          }
        />
        <Route
          exact
          path="/leader"
          element={
            <>
              <Helmet>
                <title>Leadership - Akhuwat</title>
                <meta name="description" content="Our Leadrship" />
              </Helmet>
              {isAuthenticated &&<Leadership />}
            </>
          }
        />
        <Route
          exact
          path="/programs/community"
          element={
            <>
              <Helmet>
                <title>Community - Akhuwat</title>
                <meta name="description" content="Our community" />
              </Helmet>
             {isAuthenticated &&<Community />}
            </>
          }
        />
        <Route
          exact
          path="/programs/disaster"
          element={
            <>
              <Helmet>
                <title>Disaster - Akhuwat</title>
                <meta name="description" content="Disaster management" />
              </Helmet>
              {isAuthenticated &&<Disaster /> }
            </>
          }
        />
        <Route
          exact
          path="/book"
          element={
            <>
              <Helmet>
                <title>Makhwat Book - Akhuwat</title>
                <meta name="description" content="Our Launched books" />
              </Helmet>
              {isAuthenticated &&<MakhwatBook />}
            </>
          }
        />
        <Route
          exact
          path="/program"
          element={
            <>
              <Helmet>
                <title>Perwaz Program - Akhuwat</title>
                <meta name="description" content="Our Programs" />
              </Helmet>
              {isAuthenticated &&<PerwazProgram />}
            </>
          }
        />
        {/* sub pages */}
        <Route
          exact
          path="/programs/loan/process"
          element={
            <>
              <Helmet>
                <title>Loan Process - Akhuwat</title>
                <meta name="description" content="Loan management" />
              </Helmet>
              {isAuthenticated &&<LoanProcess />}
            </>
          }
        />
        <Route
          exact
          path="/programs/loan/products"
          element={
            <>
              <Helmet>
                <title>Loan Products - Akhuwat</title>
                <meta name="description" content="Description for the Loan Products page." />
              </Helmet>
             {isAuthenticated && <LoanProducts />}
            </>
          }
        />
        <Route
          exact
          path="/involved"
          element={
            <>
              <Helmet>
                <title>Get Involved - Akhuwat</title>
                <meta name="description" content="Description for the Get Involved page." />
              </Helmet>
              {isAuthenticated &&<GetInvolved />}
            </>
          }
        />
        <Route
          exact
          path="/join"
          element={
            <>
              <Helmet>
                <title>Join Us - Akhuwat</title>
                <meta name="description" content="Description for the Join Us page." />
              </Helmet>
             {isAuthenticated &&<JoinUs />}
            </>
          }
        />
        <Route
          exact
          path="/priority"
          element={
            <>
              <Helmet>
                <title>Akhuwat Priority - Akhuwat</title>
                <meta name="description" content="Description for the Akhuwat Priority page." />
              </Helmet>
              {isAuthenticated &&<AkhuwatPriority />}
            </>
          }
        />
        <Route
          exact
          path="/story"
          element={
            <>
              <Helmet>
                <title>Akhuwat Story - Akhuwat</title>
                <meta name="description" content="Description for the Akhuwat Story page." />
              </Helmet>
              {isAuthenticated &&<AkhuwatStory />}
            </>
          }
        />
        <Route
          exact
          path="/philosophy"
          element={
            <>
              <Helmet>
                <title>Akhuwat Philosophy - Akhuwat</title>
                <meta name="description" content="Description for the Akhuwat Philosophy page." />
              </Helmet>
              {isAuthenticated &&<AkhuwatPhilosophy />}
            </>
          }
        />
        <Route
          exact
          path="/work"
          element={
            <>
              <Helmet>
                <title>Our Work - Akhuwat</title>
                <meta name="description" content="Description for the Our Work page." />
              </Helmet>
              {isAuthenticated &&<OurWork />}
            </>
          }
        />
        <Route
          exact
          path="/policy"
          element={
            <>
              <Helmet>
                <title>Privacy Policy - Akhuwat</title>
                <meta name="description" content="Description for the Privacy Policy page." />
              </Helmet>
              {isAuthenticated &&<PrivacyPolicy />}
            </>
          }
        />
        <Route
          exact
          path="/life"
          element={
            <>
              <Helmet>
                <title>Life At Akhuwat - Akhuwat</title>
                <meta name="description" content="Description for the Life At Akhuwat page." />
              </Helmet>
              {isAuthenticated &&<LifeAtAkhuwat />}
            </>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <>
              <Helmet>
                <title>Contact Us - Akhuwat</title>
                <meta name="description" content="Description for the Contact Us page." />
              </Helmet>
              {isAuthenticated &&<ContactUs />}
            </>
          }
        />
        <Route
          exact
          path="/media"
          element={
            <>
              <Helmet>
                <title>Akhuwat Media - Akhuwat</title>
                <meta name="description" content="Description for the Akhuwat Media page." />
              </Helmet>
              {isAuthenticated &&<AkhuwatMedia />}
            </>
          }
        />
        <Route
          exact
          path="/programs/stories/hope"
          element={
            <>
              <Helmet>
                <title>Hope Stories - Akhuwat</title>
                <meta name="description" content="Description for the Hope Stories page." />
              </Helmet>
              {isAuthenticated &&<HopeStories />}
            </>
          }
        />
        <Route
          exact
          path="/programs/active"
          element={
            <>
              <Helmet>
                <title>Akhuwat Active - Akhuwat</title>
                <meta name="description" content="Description for the Akhuwat Active page." />
              </Helmet>
              {isAuthenticated &&<AkhuwatActive />}
            </>
          }
        />

        {/** Branch pages */}

        <Route
          exact
          path="/college/kasur"
          element={
            <>
              <Helmet>
                <title>Akhuwat  - Kasur college</title>
                <meta name="description" content="Description for the Akhuwat Active page." />
              </Helmet>
              {isAuthenticated &&<AkhuwatKasur />}
            </>
          }
        />
               <Route path="/createBlog" element={isAuthenticated && <BlogUpload />} />
               <Route path="/add/job" element={<JobUpload />} />
               <Route path="/admin/jobs" element={<AllJobs/>} />
               <Route path="/open/jobs" element={<OpenPositions />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route exact path="/login" element={!isAuthenticated && <Login/>} ></Route>
                <Route exact path="/admin/blogs" element={isAuthenticated && <Blogs/>} ></Route>
                <Route exact path="/register" element={ <Register/>} ></Route>
                <Route path="/blogs/:id" element={<BlogDetail />} />

      </Routes>
      
              
          

      <Footer />
    </Router>
    </>
  );
}

export default App;
