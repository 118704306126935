import React from 'react';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box } from '@material-ui/core';
import CustomTypography from './CustomTypography';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import OurPartener from './OurPartener';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, expanded }) => ({
  borderRadius: '16px',
  backgroundColor: expanded ? '#F4F9EB' : 'transparent',
  padding: '16px 16px 16px 16px',
  gap: theme.spacing(0),
  opacity: 1,
  [theme.breakpoints.down('sm')]: {
    padding: '16px 8px 16px 8px',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      props.expanded ? (
        <RemoveCircleOutlineIcon sx={{ fontSize: '1.2rem', color: '#737373', width: '24px', height: '24px', marginRight: '8px' }} />
      ) : (
        <AddCircleOutlineIcon sx={{ fontSize: '1.2rem', color: '#7373732', width: '24px', height: '24px', marginRight: '8px' }} />
      )
    }
    {...props}
  />
))(({ theme, expanded }) => ({
  backgroundColor: expanded ? '#F4F9EB' : theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#FFFFFF',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingTop: '8px',
  paddingLeft: '55px'

}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };



  return (
    <Box>
      <Box className="faq-section  h-auto w-full lg:py-140px lg:px-16 lg:gap-24 py-10 px-4 gap-8">
        <Box>


          <CustomTypography as="h2" variant="h21" color="text-customBlack" weightFont="font-bold"
            alignment='text-center'>
            Frequently Asked Questions (FAQ)
          </CustomTypography>


          <CustomTypography className='pt-5' as="p" variant="p2" color="text-SlateGray" weightFont="font-bold"
            alignment='text-center'>
            Everything You Need to Know About Making a Difference
          </CustomTypography>
        </Box>

        <Box className="flex lg:justify-center justify-start lg:pt-10 pt-8 ">
          <Box className="flex flex-col lg:items-center items-left w-full max-w-[768px]">
            {/* Accordion 1 */}
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
              style={{ width: '100%' }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expanded={expanded === 'panel1'}

              >
                <CustomTypography as="p" variant="p3" color="text-customBlack" weightFont="font-semibold">
                  How can I make a donation?
                </CustomTypography>

              </AccordionSummary>
              <AccordionDetails style={{ justifyContent: 'center' }}>
                <CustomTypography as="p" variant="p3" color="text-topGray" weightFont="font-normal">
                You can make a donation through our secure online donation form.
                  Simply click on the "Donate Now" button on our homepage, choose
                  your preferred donation amount, and follow the instructions to complete your contribution.
                </CustomTypography>
                
              </AccordionDetails>
            </Accordion>

            {/* Accordion 2 */}
            <Accordion 
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
              style={{ width: '100%'  }}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                expanded={expanded === 'panel2'}
              >
                <CustomTypography as="p" variant="p3" color="text-customBlack" weightFont="font-semibold">
                  Is my donation tax-deductible?
                </CustomTypography>

              </AccordionSummary>
              <AccordionDetails style={{ justifyContent: 'center' }}>
      
                <CustomTypography as="p" variant="p3" color="text-topGray" weightFont="font-normal">
                You can make a donation through our secure online donation form.
                  Simply click on the "Donate Now" button on our homepage, choose
                  your preferred donation amount, and follow the instructions to complete your contribution.
                </CustomTypography>
              </AccordionDetails>
            </Accordion>

            {/* Accordion 3 */}
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
                expanded={expanded === 'panel3'}
              >

                <CustomTypography as="p" variant="p3" color="text-customBlack" weightFont="font-semibold">
                  Can I set up a recurring donation?
                </CustomTypography>

              </AccordionSummary>
              <AccordionDetails style={{ justifyContent: 'center' }}>

                <CustomTypography as="p" variant="p3" color="text-topGray" weightFont="font-normal">
                  You can make a donation through our secure online donation form.
                  Simply click on the "Donate Now" button on our homepage, choose
                  your preferred donation amount, and follow the instructions to complete your contribution.
                </CustomTypography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

      </Box>

      <OurPartener />
    </Box>
  );
}
